import React from 'react';
import { Typography } from '@mui/material';

interface InformationFieldProps {
  label: string;
  value?: string | number | null;
  align?: 'left' | 'center' | 'right';
}

const InformationField: React.FunctionComponent<InformationFieldProps> = (props) => {
  const { label, value, align } = props;

  return (
    <div>
      <Typography variant="subtitle1" component="h6" style={{ fontWeight: 600, fontSize: 16, textAlign: align }}>
        {label}
      </Typography>
      <Typography variant="body1" component="p" style={{ whiteSpace: 'break-spaces', fontSize: 14, textAlign: align }}>
        {value}
      </Typography>
    </div>
  );
};

export default InformationField;
