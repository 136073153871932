import React, { Dispatch, SetStateAction, useState, useEffect, useContext } from 'react';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import SelectInput from '../../form/components/SelectInput';
import TextInput from '../../form/components/TextInput';
import Save from '@mui/icons-material/Save';
import Delete from '@mui/icons-material/Delete';
import InputAdornment from '@mui/material/InputAdornment';

import { EstimateDetailsContext } from '../../estimateDetails/contexts/EstimateDetailsContext';
import { SystemAccessoriesContext } from '../../adminSettings/contexts/SystemAccessoriesContext';

import { SystemAccessoryRecord } from '../../estimateDetails/model';

import axios from '../../utils/axios.utils';

interface SystemAccessoryRecordFormRowProps {
  initialSystemAccessoryRecord: SystemAccessoryRecord;
  setSystemAccessoryRecords: Dispatch<SetStateAction<SystemAccessoryRecord[]>>;
  handleUnsavedChanges: (id: string, hasChanges: boolean) => void;
}

const SystemAccessoryRecordFormRow: React.FC<SystemAccessoryRecordFormRowProps> = (props) => {
  let { initialSystemAccessoryRecord, setSystemAccessoryRecords, handleUnsavedChanges } = props;

  let { salesEstimate } = useContext(EstimateDetailsContext);
  let { systemAccessories } = useContext(SystemAccessoriesContext);

  let [record, setRecord] = useState<SystemAccessoryRecord>();
  let [currentSystemAccessoryRecord, setCurrentSystemAccessoryRecord] = useState<SystemAccessoryRecord>();
  let [thisRecordNeedsSaving, setThisRecordNeedsSaving] = useState<boolean>(false);

  let assetChoices = salesEstimate.sales_estimate_assets.map((sales_estimate_asset) => {
    return {
      value: sales_estimate_asset.id,
      label: sales_estimate_asset.asset?.asset_number,
    };
  });

  assetChoices.unshift({ value: '0', label: 'Standalone Accessory' });
  assetChoices.unshift({ value: '-1', label: 'Assign to All' });

  let systemAccessoryChoices = systemAccessories.map((accessory) => {
    return {
      value: accessory.id.toString(),
      label: `${accessory.name}`,
    };
  });

  useEffect(() => {
    setRecord(initialSystemAccessoryRecord);
    setCurrentSystemAccessoryRecord(initialSystemAccessoryRecord);
  }, [initialSystemAccessoryRecord]);

  // Add the "Other" option
  systemAccessoryChoices.push({ value: 'other', label: 'Other' });

  useEffect(() => {
    if (record) {
      let hasChanges = JSON.stringify(record) !== JSON.stringify(currentSystemAccessoryRecord);
      handleUnsavedChanges(initialSystemAccessoryRecord.id, hasChanges);
      setThisRecordNeedsSaving(hasChanges);
    }
  }, [record]);

  let handleFormChanged = async (key: any, event: any) => {
    let newRecord = { ...record, [key]: event.target.value };

    // If the user selects an existing accessory
    if (key === 'system_accessory_id' && event.target.value !== 'other') {
      let systemAccessory = systemAccessories.find((accessory) => accessory.id.toString() === event.target.value);
      if (systemAccessory) {
        newRecord.unit_cost = systemAccessory.cost;
        newRecord.custom_accessory_name = undefined; // Clear custom name if previously set
      }
    }

    // If the user chooses 'Other', we clear the cost since it's custom
    if (key === 'system_accessory_id' && event.target.value === 'other') {
      newRecord.unit_cost = '';
      // Initialize a field to hold the custom accessory name
      newRecord.custom_accessory_name = '';
      // newRecord.system_accessory_id = '-1';
    }

    setRecord(newRecord);
  };

  let updateRecord = async () => {
    let response: any = await axios.put(`/system_accessory_records/${record.id}`, {
      system_accessory_record: record,
    });
    let updatedRecord = response.data.result;
    setRecord(updatedRecord);
    setCurrentSystemAccessoryRecord(updatedRecord);
  };

  let handleDelete = async () => {
    let response: any = await axios.delete(`/system_accessory_records/${record.id}`);
    let updatedRecords = response.data.result;
    setSystemAccessoryRecords(updatedRecords);
  };

  return (
    <Card style={{ marginBottom: 15, padding: 10 }}>
      <Grid container spacing={3}>
        <Grid item md={3} xs={6}>
          <SelectInput
            label="Asset"
            value={record?.sales_estimate_asset_id}
            choices={assetChoices}
            onChange={(event) => {
              handleFormChanged('sales_estimate_asset_id', event);
            }}
          />
        </Grid>
        <Grid item md={4} xs={6}>
          <SelectInput
            label="Item Name"
            value={record?.system_accessory_id}
            choices={systemAccessoryChoices}
            onChange={(event) => {
              handleFormChanged('system_accessory_id', event);
            }}
          />
        </Grid>
        <Grid item md={2} xs={6}>
          <TextInput
            label="Cost"
            currency
            value={record?.unit_cost}
            onChange={(event) => handleFormChanged('unit_cost', event)}
          />
        </Grid>

        {/* Conditionally render the custom accessory name field if "Other" is chosen */}
        {record?.system_accessory_id === 'other' && (
          <Grid item md={3} xs={6}>
            <TextInput
              label="Custom Accessory Name"
              value={record?.custom_accessory_name || ''}
              onChange={(event) => {
                handleFormChanged('custom_accessory_name', event);
              }}
            />
          </Grid>
        )}
        <Grid item xs={1}>
          <Button
            variant="contained"
            color="primary"
            style={{
              height: '55px',
              border: thisRecordNeedsSaving ? '5px solid red' : '1px solid #3f51b5',
            }}
            onClick={updateRecord}
          >
            <Save />
          </Button>
        </Grid>
        <Grid item xs={1} style={{ display: 'flex', alignItems: 'center' }}>
          <Button variant="contained" color="warning" onClick={handleDelete}>
            <Delete />
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
};

export default SystemAccessoryRecordFormRow;
