import React, { useState, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import { RebatesContext } from '../../adminSettings/contexts/RebatesContext';
import { EstimateDetailsContext } from '../../estimateDetails/contexts/EstimateDetailsContext';
import SalesEstimateStepper from '../../navigation/components/SalesEstimateStepper';
import EstimateInfoHeader from '../../estimateCustomers/components/EstimateInfoHeader';

import { Rebate } from '../model';
import RebateCardCollection from '../components/RebateCardCollection';

const NewRebates: React.FC = () => {
  const history = useHistory();

  const { estimateId } = useParams<{ estimateId: string }>();

  const { fetchRebates, rebates } = useContext(RebatesContext);
  const { salesEstimate, fetchSalesEstimate } = useContext(EstimateDetailsContext);

  let [hvacRebates, setHvacRebates] = useState<Rebate[]>([]);
  let [optionalRebates, setOptionalRebates] = useState<Rebate[]>([]);

  useEffect(() => {
    fetchSalesEstimate(estimateId);
    fetchRebates();
  }, []);

  useEffect(() => {
    setHvacRebates(rebates.filter((rebate) => rebate.technology === 'HVAC'));
    setOptionalRebates(rebates.filter((rebate) => rebate.technology !== 'HVAC'));
  }, [rebates]);

  let handleContinue = async () => {
    history.push(`/rooftop_automation/${estimateId}/review`);
  };

  return (
    <Grid container>
      <SalesEstimateStepper activeStep={5} salesEstimateId={estimateId} />
      <Grid item xs={12} md={10}>
        <EstimateInfoHeader salesEstimate={salesEstimate} />
      </Grid>
      <Grid item xs={12}>
        <h2 style={{ fontSize: '36px' }}>Rebates</h2>
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 20 }}>
        <RebateCardCollection rebates={hvacRebates} title="Automatically Applied HVAC Rebates" />
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 20 }}>
        <RebateCardCollection rebates={optionalRebates} title="Optional Rebates" />
      </Grid>
      <Grid item xs={12}>
        <hr />
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={handleContinue}>
          Continue to Review
        </Button>
      </Grid>
    </Grid>
  );
};

export default NewRebates;
