import react, { useState, Dispatch, SetStateAction, useEffect, useContext } from 'react';

import Grid from '@mui/material/Grid';
import TextInput from '../../form/components/TextInput';
import SelectInput from '../../form/components/SelectInput';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import axios from '../../utils/axios.utils';

import { EstimateDetailsContext } from '../../estimateDetails/contexts/EstimateDetailsContext';

interface CustomerDetailsFormProps {
  currentStep: number;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  index: number;
}

const CustomerDetailsForm: React.FC<CustomerDetailsFormProps> = (props) => {
  let { currentStep, setCurrentStep, index } = props;

  let [customerFormValues, setCustomerFormValues] = useState<any>({});
  let [formErrors, setFormErrors] = useState<any>('');
  let [customerFormOptions, setCustomerFormOptions] = useState<any>({});

  let { salesEstimate, setSalesEstimate } = useContext(EstimateDetailsContext);

  let fetchCustomerFormOptions = async () => {
    const response: any = await axios.get('/customers/new');
    setCustomerFormOptions(response.data);
  };

  useEffect(() => {
    setCustomerFormValues(salesEstimate?.customer);
  }, [salesEstimate]);

  useEffect(() => {
    fetchCustomerFormOptions();
  }, []);

  let handleSaveClicked = async () => {
    try {
      const response: any = await axios.post('/customers', {
        customer: customerFormValues,
        sales_estimate_id: salesEstimate?.id,
      });
      let updatedCustomer = response.data.result;
      setCustomerFormValues(updatedCustomer);
      setSalesEstimate((prevSalesEstimate) => ({
        ...prevSalesEstimate,
        customer: updatedCustomer,
      }));
      setCurrentStep((currentStep) => currentStep + 1);
    } catch (error: any) {
      setFormErrors(error.response.data.error);
    }
  };

  const handleSummaryClicked = () => {
    if (currentStep === index) {
      setCurrentStep(-1);
    } else {
      setCurrentStep(index);
    }
  };

  return (
    <Accordion expanded={currentStep === index}>
      <AccordionSummary expandIcon={<ArrowDropDownIcon />} onClick={handleSummaryClicked}>
        <Grid item xs={12}>
          <h2 style={{ fontSize: '18px' }}>Customer Details</h2>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextInput
              label="Customer Name (Required)"
              value={customerFormValues?.name}
              shrinkLabel
              valueChanged={(value: string) => setCustomerFormValues({ ...customerFormValues, name: value })}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SelectInput
              label="Customer Type (Required)"
              choices={customerFormOptions?.customer_type_choices}
              value={customerFormValues?.customer_type_id}
              valueChanged={(value: string) =>
                setCustomerFormValues({ ...customerFormValues, customer_type_id: value })
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <p style={{ color: 'red' }}>{formErrors}</p>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={handleSaveClicked}>
              Save Customer Details
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default CustomerDetailsForm;
