import { MSAL_CLIENT_ID } from './env';

export const msalConfig = {
  auth: {
    clientId: MSAL_CLIENT_ID,
    authority: 'https://login.microsoftonline.com/4d79b588-0df5-4b16-a705-997e658084cd',
    redirectUri: 'https://sales.tudi.com/auth',
    postLogoutRedirectUri: 'https://sales.tudi.com/login',
  },
};
