import React, { FC, useState } from 'react';
import { Grid, Button } from '@mui/material';
import InformationField from '../../form/components/InformationField';

import { SalesEstimateAsset } from '../../estimateDetails/model';

interface ExpandedSpecifxDetailsProps {
  salesEstimateAsset: SalesEstimateAsset;
}

const ExpandedSpecifxDetails: FC<ExpandedSpecifxDetailsProps> = (props) => {
  const [allKeysExpanded, setAllKeysExpanded] = useState(false);
  const [lowPriorityExpanded, setLowPriorityExpanded] = useState(false);

  let { salesEstimateAsset } = props;

  const allKeyValues = salesEstimateAsset?.all_specifx_key_values?.fields || [];
  const highPriorityKeyValues = salesEstimateAsset?.high_priority_specifx_key_values || [];
  const lowPriorityKeyValues = salesEstimateAsset?.low_priority_specifx_key_values || [];

  const toggleAllKeysExpanded = () => {
    setAllKeysExpanded(!allKeysExpanded);
  };

  const toggleLowPriorityExpanded = () => {
    setLowPriorityExpanded(!lowPriorityExpanded);
  };

  const collapseAll = () => {
    setLowPriorityExpanded(false);
    setAllKeysExpanded(false);
  };

  return (
    <div className="expanded-row" style={{ width: '100%' }}>
      {allKeysExpanded ? (
        <>
          <Grid container spacing={3}>
            {allKeysExpanded &&
              allKeyValues?.map((key_value, index) => (
                <Grid item xs={4} key={index + 8}>
                  <InformationField label={key_value.label} value={key_value.value || 'None'} />
                </Grid>
              ))}
          </Grid>
          <Button variant="contained" color="primary" onClick={collapseAll} style={{ marginTop: '10px' }}>
            Show Less
          </Button>
        </>
      ) : (
        <>
          <Grid container spacing={3}>
            {highPriorityKeyValues?.map((key_value, index) => (
              <Grid item xs={4} key={index}>
                <InformationField label={key_value.label} value={key_value.value || 'None'} />
              </Grid>
            ))}
            {lowPriorityExpanded &&
              lowPriorityKeyValues?.map((key_value, index) => (
                <Grid item xs={4} key={index + 8}>
                  <InformationField label={key_value.label} value={key_value.value || 'None'} />
                </Grid>
              ))}
          </Grid>
          <Button variant="contained" color="primary" onClick={toggleLowPriorityExpanded} style={{ marginTop: '10px' }}>
            {lowPriorityExpanded ? 'Show Less' : 'Show More'}
          </Button>
          {lowPriorityExpanded && (
            <Button
              variant="contained"
              color="primary"
              onClick={toggleAllKeysExpanded}
              style={{ marginTop: '10px', marginLeft: '10px' }}
            >
              {allKeysExpanded ? 'Show Less' : 'Show All'}
            </Button>
          )}
        </>
      )}
    </div>
  );
};

export default ExpandedSpecifxDetails;
