import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { AlertBubbleIcon } from '@shopify/polaris-icons';
import { Button, Grid, Typography, Popper, Grow, Paper, ClickAwayListener, MenuItem, MenuList } from '@mui/material';
import AppsIcon from '@mui/icons-material/Apps';
import { Navigation } from '@shopify/polaris';
import axios from '../../utils/axios.utils';

const options = ['Sales Estimates', 'Chiller Logs', 'Project Management', 'Warehouse'];

const Sidebar: React.FC = () => {
  const location = useLocation();

  const [rebatesToReviewCount, setRebatesToReviewCount] = useState<number>(0);
  const [showSalesModule, setShowSalesModule] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [open, setOpen] = useState(false);

  const anchorRef = useRef<HTMLDivElement>(null);
  const isFetched = useRef(false); // Track if rebate records have been fetched

  const fetchRebateRecordsCount = async () => {
    try {
      const response: any = await axios.get('/rebate_records/new');
      setRebatesToReviewCount(response.data.rebates_to_review_count);
    } catch (error) {
      console.error('Error fetching rebate records count:', error);
    }
  };

  useEffect(() => {
    if (!isFetched.current) {
      fetchRebateRecordsCount();
      isFetched.current = true; // Mark fetch as completed
    }
  }, []);

  const reviewRebatesLabel = `Rebates to Review ${rebatesToReviewCount > 0 ? `(${rebatesToReviewCount})` : ''}`;

  const handleClick = () => {
    console.info(`You clicked ${options[selectedIndex]}`);
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
    setSelectedIndex(index);
    setShowSalesModule(index === 0);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  return (
    <Navigation location={location.pathname}>
      <Navigation.Section
        items={[
          {
            label: 'Sales Estimates',
            url: '/rooftop_automation/customers/search',
            icon: AlertBubbleIcon,
            matches: true,
            subNavigationItems: [
              // {
              //   url: '/rooftop_automation/customers/new',
              //   disabled: false,
              //   label: 'New Customer',
              // },
              {
                url: '/rooftop_automation/customers/search',
                disabled: false,
                label: 'Existing Customer',
              },
              {
                url: '/rooftop_automation/recent',
                disabled: false,
                label: 'Recent Estimates',
              },
              // {
              //   url: '/rooftop_automation/rebate_records',
              //   disabled: false,
              //   label: reviewRebatesLabel,
              // },
            ],
          },
          {
            label: 'Estimate Settings',
            url: '/admin',
            icon: AlertBubbleIcon,
            matches: true,
            subNavigationItems: [
              {
                url: '/admin/equipment_costs',
                disabled: false,
                label: 'Replacement Equipment',
              },
              {
                url: '/admin/system_accessories',
                disabled: false,
                label: 'System Accessories',
              },
              {
                url: '/admin/installation_costs',
                disabled: false,
                label: 'Installation Costs',
              },
              {
                url: '/admin/rebates',
                disabled: false,
                label: 'Rebate Options',
              },
              {
                url: '/xoi_jobs',
                disabled: false,
                label: 'XOi Job Lookup',
              },
            ],
          },
          {
            label: 'ServMan Data',
            url: '/admin',
            icon: AlertBubbleIcon,
            matches: true,
            subNavigationItems: [
              {
                url: '/admin/servman_sql_logs',
                disabled: false,
                label: 'SQL Logs',
              },
              {
                url: '/admin/servman_customers',
                disabled: false,
                label: 'Customers',
              },
              {
                url: '/admin/servman_assets',
                disabled: false,
                label: 'Assets',
              },
              {
                url: '/admin/servman_equipment_types',
                disabled: false,
                label: 'Equipment Types',
              },
              {
                url: '/admin/servman_client_types',
                disabled: false,
                label: 'Client Types',
              },
              {
                url: '/admin/servman_contact_types',
                disabled: false,
                label: 'Contact Types',
              },
              {
                url: '/admin/servman_phone_types',
                disabled: false,
                label: 'Phone Types',
              },
              {
                url: '/admin/servman_manufacturers',
                disabled: false,
                label: 'Manufacturers',
              },
              {
                url: '/admin/zip_codes',
                disabled: false,
                label: 'Zip Codes',
              },
            ],
          },
          {
            label: 'Admin',
            url: '/admin',
            icon: AlertBubbleIcon,
            matches: true,
            subNavigationItems: [
              {
                url: '/admin/user_management',
                disabled: false,
                label: 'User Management',
              },
            ],
          },
        ]}
      />
    </Navigation>
  );
};

export default Sidebar;
