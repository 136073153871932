import React from 'react';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import InformationField from '../../form/components/InformationField';

import { SalesEstimate } from '../model';

interface ContactAndPhoneProps {
  salesEstimate: SalesEstimate;
}

const ContactAndPhone: React.FC<ContactAndPhoneProps> = (props) => {
  let { salesEstimate } = props;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Card style={{ height: 'auto' }}>
          <CardContent>
            <Grid container>
              <Grid style={{ marginTop: 10 }} item xs={12}>
                <InformationField label="Contact" value={salesEstimate?.contact_name} />
              </Grid>
              <Grid style={{ marginTop: 10 }} item xs={12}>
                <InformationField label="Email" value={salesEstimate?.contact_email} />
              </Grid>
              <Grid style={{ marginTop: 10 }} item xs={12}>
                <InformationField label="Phone" value={salesEstimate?.contact_phone} />
              </Grid>
              <Grid style={{ marginTop: 10 }} item xs={12}>
                <InformationField label="Site Address" value={salesEstimate?.address} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ContactAndPhone;
