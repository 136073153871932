import react, { useState, Dispatch, SetStateAction, useEffect, useContext } from 'react';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Autocomplete from '@mui/material/Autocomplete';
import TextInput from '../../form/components/TextInput';

import axios from '../../utils/axios.utils';

import { SalesEstimate } from '../../estimateDetails/model';

interface SalesEstimateDetailsFormProps {
  salesEstimateFormValues: SalesEstimate;
  setSalesEstimateFormValues: Dispatch<SetStateAction<SalesEstimate>>;
}

const filterDuplicates = (choices) => {
  const uniqueLabels = new Set();
  return choices.filter((choice) => {
    if (uniqueLabels.has(choice.label)) {
      return false;
    }
    uniqueLabels.add(choice.label);
    return true;
  });
};

const SalesEstimateDetailsForm: React.FC<SalesEstimateDetailsFormProps> = (props) => {
  let { salesEstimateFormValues, setSalesEstimateFormValues } = props;

  let [opportunityChoices, setOpportunityChoices] = useState<any>({});
  let [selectedOpportunity, setSelectedOpportunity] = useState<any>(null);

  let updateSelectedOpportunity = () => {
    if (opportunityChoices?.length > 0) {
      setSelectedOpportunity(
        opportunityChoices?.find((option) => option.value === salesEstimateFormValues?.xoi_job_id),
      );
    }
  };

  let fetchOpportunityChoices = async () => {
    const response: any = await axios.get('/sales_estimates/new');
    setOpportunityChoices(response.data?.opportunity_choices);
  };

  useEffect(() => {
    fetchOpportunityChoices();
    if (!salesEstimateFormValues.gross_profit_integer) {
      setSalesEstimateFormValues({ ...salesEstimateFormValues, gross_profit_integer: '30' });
    }
  }, []);

  useEffect(() => {
    updateSelectedOpportunity();
  }, [opportunityChoices, salesEstimateFormValues]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} style={{ marginBottom: 15 }}>
        <TextInput
          label="Estimate Name (Required)"
          value={salesEstimateFormValues.name}
          shrinkLabel
          valueChanged={(value: string) => setSalesEstimateFormValues({ ...salesEstimateFormValues, name: value })}
        />
      </Grid>
      <Grid item xs={12} md={6} style={{ marginBottom: 10 }}>
        <TextInput
          label="Quote Number (Optional)"
          value={salesEstimateFormValues.quote_number}
          shrinkLabel
          valueChanged={(value: string) =>
            setSalesEstimateFormValues({ ...salesEstimateFormValues, quote_number: value })
          }
        />
      </Grid>
      {opportunityChoices?.length > 0 && (
        <Grid item xs={12} style={{ marginBottom: 15 }}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={filterDuplicates(opportunityChoices)}
            getOptionLabel={(option) => option.label}
            sx={{ width: '100%' }}
            value={selectedOpportunity}
            onChange={(event, newValue) =>
              setSalesEstimateFormValues({ ...salesEstimateFormValues, xoi_job_id: newValue?.value })
            }
            renderInput={(params) => <TextField {...params} label="Opportunity Name (Optional)" />}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default SalesEstimateDetailsForm;
