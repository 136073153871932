export const compressor1Data = [
  [
    { value: '07-02', readOnly: true },
    { value: '07-22', readOnly: true },
    { value: '01-25', readOnly: true },
    { value: '07-02', readOnly: true },
    { value: '07-22', readOnly: true },
    { value: '01-25', readOnly: true },
  ],
  [
    { value: '62.0', readOnly: true },
    { value: '0.0', readOnly: true },
    { value: '0.0' },
    { value: '62.0', readOnly: true },
    { value: '0.0', readOnly: true },
    { value: '0.0' },
  ],
  [
    { value: '82.0', readOnly: true },
    { value: '89.0', readOnly: true },
    { value: '0.0' },
    { value: '82.0', readOnly: true },
    { value: '89.0', readOnly: true },
    { value: '0.0' },
  ],
  [
    { value: '66.5', readOnly: true },
    { value: '54.2', readOnly: true },
    { value: '0.0' },
    { value: '66.5', readOnly: true },
    { value: '54.2', readOnly: true },
    { value: '0.0' },
  ],
  [
    { value: '62.3', readOnly: true },
    { value: '45.2', readOnly: true },
    { value: '0.0' },
    { value: '62.3', readOnly: true },
    { value: '45.2', readOnly: true },
    { value: '0.0' },
  ],
  [
    { value: '0', readOnly: true },
    { value: '0', readOnly: true },
    { value: '0.0' },
    { value: '0', readOnly: true },
    { value: '0', readOnly: true },
    { value: '0.0' },
  ],
  [
    { value: '4.2', readOnly: true },
    { value: '10.0', readOnly: true },
    { value: '0.0' },
    { value: '4.2', readOnly: true },
    { value: '10.0', readOnly: true },
    { value: '0.0' },
  ],
  [
    { value: '15.0', readOnly: true },
    { value: '8.3', readOnly: true },
    { value: '0.0' },
    { value: '15.0', readOnly: true },
    { value: '8.3', readOnly: true },
    { value: '0.0' },
  ],
  [
    { value: '66.5', readOnly: true },
    { value: '54.2', readOnly: true },
    { value: '0.0' },
    { value: '66.5', readOnly: true },
    { value: '54.2', readOnly: true },
    { value: '0.0' },
  ],
  [
    { value: '62.3', readOnly: true },
    { value: '45.2', readOnly: true },
    { value: '0.0' },
    { value: '62.3', readOnly: true },
    { value: '45.2', readOnly: true },
    { value: '0.0' },
  ],
  [
    { value: '0', readOnly: true },
    { value: '0', readOnly: true },
    { value: '0.0' },
    { value: '0', readOnly: true },
    { value: '0', readOnly: true },
    { value: '0.0' },
  ],
  [
    { value: '4.2', readOnly: true },
    { value: '10.0', readOnly: true },
    { value: '0.0' },
    { value: '4.2', readOnly: true },
    { value: '10.0', readOnly: true },
    { value: '0.0' },
  ],
  [
    { value: '15.0', readOnly: true },
    { value: '8.3', readOnly: true },
    { value: '0.0' },
    { value: '15.0', readOnly: true },
    { value: '8.3', readOnly: true },
    { value: '0.0' },
  ],
  [
    { value: '66.5', readOnly: true },
    { value: '54.2', readOnly: true },
    { value: '0.0' },
    { value: '66.5', readOnly: true },
    { value: '54.2', readOnly: true },
    { value: '0.0' },
  ],
  [
    { value: '62.3', readOnly: true },
    { value: '45.2', readOnly: true },
    { value: '0.0' },
    { value: '62.3', readOnly: true },
    { value: '45.2', readOnly: true },
    { value: '0.0' },
  ],
  [
    { value: '0', readOnly: true },
    { value: '0', readOnly: true },
    { value: '0.0' },
    { value: '0', readOnly: true },
    { value: '0', readOnly: true },
    { value: '0.0' },
  ],
  [
    { value: '4.2', readOnly: true },
    { value: '10.0', readOnly: true },
    { value: '0.0' },
    { value: '4.2', readOnly: true },
    { value: '10.0', readOnly: true },
    { value: '0.0' },
  ],
  [
    { value: '15.0', readOnly: true },
    { value: '8.3', readOnly: true },
    { value: '0.0' },
    { value: '15.0', readOnly: true },
    { value: '8.3', readOnly: true },
    { value: '0.0' },
  ],
  [
    { value: '15.0', readOnly: true },
    { value: '8.3', readOnly: true },
    { value: '0.0' },
    { value: '15.0', readOnly: true },
    { value: '8.3', readOnly: true },
    { value: '0.0' },
  ],
];
