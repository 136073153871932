import React, { FunctionComponent, useState, useContext } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Grid, Link } from '@mui/material';
import TextInput from '../../../modules/form/components/TextInput';
import { AuthenticationContext } from '../contexts/AuthenticationContext';
import { useMsal, useAccount } from '@azure/msal-react';

const useStyles = makeStyles({
  textInput: {
    height: '60px',
    width: '80%',
    margin: '10px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
});

const LoginForm: FunctionComponent = () => {
  const classes = useStyles();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { login } = useContext(AuthenticationContext);

  const { instance } = useMsal();

  const handleMicrosoftLogin = () => {
    login('admin@tudi.com', 'seriousaboutservice', null);
    instance.loginPopup({ scopes: [] }).catch((e) => {
      console.error(e);
    });
  };

  const handleLogin = (e: React.FormEvent) => {
    e.preventDefault();
    console.log(email);
    if (password === 'seriousaboutservice' && email === 'admin@tudi.com') {
      login(email, password, () => (window.location.href = '/rooftop_automation/recent'));
    } else {
      setErrorMessage('or username is invalid.');
    }
  };

  return (
    <form noValidate autoComplete="off" onSubmit={handleLogin}>
      <Button onClick={handleLogin}>Sign In</Button>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextInput
            testId="email-input"
            styleOverrides={classes.textInput}
            shrinkLabel
            label="Email or Username"
            value={email}
            valueChanged={(value: string) => setEmail(value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            styleOverrides={classes.textInput}
            shrinkLabel
            type="password"
            label="Password"
            errorMessage={errorMessage}
            value={password}
            valueChanged={(value: string) => setPassword(value)}
          />
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" type="submit">
            Login
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleMicrosoftLogin}
            style={{ marginLeft: '10px', background: 'white' }}
          >
            <img src="https://cdn-icons-png.flaticon.com/512/732/732221.png" alt="Windows Icon" height="24px" />
            <span style={{ marginLeft: 7, color: '#333' }}>Microsoft Login</span>
          </Button>
        </Grid>
        <Grid item>
          <Link style={{ lineHeight: '42px' }} href="/password-reset">
            Forgot your password?
          </Link>
        </Grid>
      </Grid>
    </form>
  );
};

export default LoginForm;
