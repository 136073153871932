import react, { useState, Dispatch, SetStateAction, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import TextInput from '../../form/components/TextInput';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import axios from '../../utils/axios.utils';

import { SalesEstimate } from '../../estimateDetails/model';

interface SiteDetailsFormProps {
  salesEstimate: SalesEstimate;
  setSalesEstimate: Dispatch<SetStateAction<SalesEstimate>>;
  currentStep: number;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  index: number;
}

const SiteDetailsForm: React.FC<SiteDetailsFormProps> = (props) => {
  let { salesEstimate, setSalesEstimate, currentStep, setCurrentStep, index } = props;

  let [siteFormValues, setSiteFormValues] = useState<any>({});
  let [formErrors, setFormErrors] = useState<any>({});

  useEffect(() => {
    let loadedSite = salesEstimate?.site;
    if (loadedSite?.id) {
      loadedSite.customer_id = salesEstimate?.customer?.id;
      setSiteFormValues(loadedSite);
    }
  }, [salesEstimate]);

  let handleSaveClicked = async () => {
    try {
      const response: any = await axios.put(`/sites/${salesEstimate?.site?.id}`, {
        site: siteFormValues,
        sales_estimate_id: salesEstimate?.id,
      });
      let updatedSite = response.data.result;
      setSiteFormValues(updatedSite);
      setSalesEstimate((prevSalesEstimate) => ({
        ...prevSalesEstimate,
        site: updatedSite,
      }));
      setCurrentStep((currentStep) => currentStep + 1);
    } catch (error: any) {
      setFormErrors(error.response.data.error);
    }
  };

  const handleSummaryClicked = () => {
    if (currentStep === index) {
      setCurrentStep(-1);
    } else {
      setCurrentStep(index);
    }
  };

  return (
    <Accordion expanded={currentStep === index}>
      <AccordionSummary expandIcon={<ArrowDropDownIcon />} onClick={handleSummaryClicked}>
        <Grid item xs={12}>
          <h2 style={{ fontSize: '18px' }}>Site Details</h2>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextInput
              label="Location Name"
              errorMessage={formErrors?.name?.[0]}
              value={siteFormValues?.name}
              shrinkLabel
              valueChanged={(value: string) => setSiteFormValues({ ...siteFormValues, name: value })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              label="Address 1"
              errorMessage={formErrors?.address1?.[0]}
              value={siteFormValues?.address1}
              shrinkLabel
              valueChanged={(value: string) => setSiteFormValues({ ...siteFormValues, address1: value })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              label="Address 2"
              errorMessage={formErrors?.address2?.[0]}
              value={siteFormValues?.address2}
              shrinkLabel
              valueChanged={(value: string) => setSiteFormValues({ ...siteFormValues, address2: value })}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextInput
              label="City"
              errorMessage={formErrors?.city?.[0]}
              value={siteFormValues?.city}
              shrinkLabel
              valueChanged={(value: string) => setSiteFormValues({ ...siteFormValues, city: value })}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextInput
              label="State"
              errorMessage={formErrors?.state?.[0]}
              value={siteFormValues?.state}
              shrinkLabel
              valueChanged={(value: string) => setSiteFormValues({ ...siteFormValues, state: value })}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextInput
              label="Zip"
              errorMessage={formErrors?.zip?.[0]}
              value={siteFormValues?.zip}
              shrinkLabel
              valueChanged={(value: string) => setSiteFormValues({ ...siteFormValues, zip: value })}
            />
          </Grid>

          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={handleSaveClicked}>
              Save Site Details
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default SiteDetailsForm;
