import Button from '@mui/material/Button';
import { IDataTableColumn } from 'react-data-table-component';

export const rebateDetailsColumns: IDataTableColumn<any>[] = [
  {
    name: 'Rebate Name',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'Source',
    selector: 'source',
    sortable: true,
  },
  {
    name: 'Utility Provider',
    selector: 'utility_provider',
    sortable: true,
  },
  {
    name: 'L2 Description',
    selector: 'l2_description',
    sortable: true,
  },
  {
    name: 'Stage',
    selector: 'stage',
    sortable: true,
  },
  {
    name: 'Actions',
    selector: 'code',
    sortable: true,
    grow: 1.5,
    cell: (row) => (
      <a href={`/rebate_records/${row.id}`} target="_blank" rel="noreferrer">
        <Button variant="contained" color="primary">
          Review
        </Button>
      </a>
    ),
  },
];

const changeLogColumns: IDataTableColumn<any>[] = [
  {
    name: 'Field',
    selector: 'name',
    sortable: true,
    cell: (row) => <p>{row.asset_number}</p>,
  },
  {
    name: 'Previous Value',
    selector: 'name',
    sortable: true,
    cell: (row) => <p>{row.model_number}</p>,
  },
  {
    name: 'Updated Value',
    selector: 'name',
    sortable: true,
    cell: (row) => <p>{row.model_number_replacement}</p>,
  },
  {
    name: 'Performed By',
    selector: 'name',
    sortable: true,
    cell: (row) => <p>{row.size}</p>,
  },
  {
    name: 'Timestamp',
    selector: 'vintage',
    sortable: true,
    cell: (row) => <p>{row.vintage}</p>,
  },
];

const keyDetailColumns: IDataTableColumn<any>[] = [
  {
    name: 'Folder Clean',
    selector: 'name',
    sortable: false,
    cell: (row) => <p>Yes</p>,
  },
  {
    name: 'Tax Exmpt',
    selector: 'name',
    sortable: false,
    cell: (row) => <p>No</p>,
  },
  {
    name: 'Night Work',
    selector: 'name',
    sortable: false,
    cell: (row) => <p>No</p>,
  },
  {
    name: 'Wknd Work',
    selector: 'name',
    sortable: false,
    cell: (row) => <p>No</p>,
  },
  {
    name: 'POC',
    selector: 'vintage',
    sortable: false,
    cell: (row) => <p>david</p>,
  },
  {
    name: 'Urgency',
    selector: 'vintage',
    sortable: false,
    cell: (row) => <p>ASAP</p>,
  },
  {
    name: 'Permit REQ',
    selector: 'vintage',
    sortable: false,
    cell: (row) => <p>No</p>,
  },
  {
    name: 'Turnover Mtg',
    selector: 'vintage',
    sortable: false,
    cell: (row) => <p>Phone</p>,
  },
  {
    name: 'Closeout Type',
    selector: 'vintage',
    sortable: false,
    cell: (row) => <p>None</p>,
  },
  {
    name: 'Startup Type',
    selector: 'vintage',
    sortable: false,
    cell: (row) => <p>Projects</p>,
  },
  {
    name: 'Lead Tech',
    selector: 'vintage',
    sortable: false,
    cell: (row) => <p>ANY</p>,
  },
];

export const costSummaryColumns: IDataTableColumn<any>[] = [
  {
    name: 'Category',
    selector: 'category',
    sortable: true,
    cell: (row) => <p style={{ textTransform: 'capitalize', fontWeight: 700 }}>{row.category}</p>,
  },
  {
    name: 'Cost',
    selector: 'cost',
    sortable: true,
  },
  {
    name: 'Tax',
    selector: 'tax_amount',
    sortable: true,
  },
  // If we want to combine cost and tax, we can use cost_with_tax instead
  // Maybe we could even offer a toggle for this
  // {
  //   name: 'Cost with Tax',
  //   selector: 'cost_with_tax',
  //   sortable: true,
  // },
  {
    name: 'GP %',
    selector: 'gross_profit_integer',
    sortable: true,
    cell: (row) => <div>35%</div>,
  },
  {
    name: 'Margin',
    selector: 'gross_profit',
    sortable: true,
  },
  {
    name: 'Sale Price',
    selector: 'sale_price',
    sortable: true,
  },
];

export const reviewAssetColumns: IDataTableColumn<any>[] = [
  {
    name: 'Asset Name',
    selector: 'name',
    sortable: true,
    cell: (row) => <p>{row.asset_number}</p>,
  },
  {
    name: 'Existing Model Number',
    selector: 'name',
    sortable: true,
    cell: (row) => <p>{row.model_number}</p>,
  },
  {
    name: 'Replacement Model Number',
    selector: 'name',
    sortable: true,
    cell: (row) => <p>{row.model_number_replacement}</p>,
  },
  {
    name: 'Size',
    selector: 'name',
    sortable: true,
    cell: (row) => <p>{row.size}</p>,
  },
  {
    name: 'Age',
    selector: 'age',
    sortable: true,
    cell: (row) => <p>{row.age}</p>,
  },
];
