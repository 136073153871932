import React from 'react';
import { Grid } from '@mui/material';
import InformationField from '../../form/components/InformationField';
import { SalesEstimate } from '../../estimateDetails/model';

interface EstimateInfoHeaderProps {
  salesEstimate: SalesEstimate;
  downloadButton?: any;
}

const EstimateInfoHeader: React.FC<EstimateInfoHeaderProps> = (props: EstimateInfoHeaderProps) => {
  const { salesEstimate, downloadButton } = props;

  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <h2 style={{ fontSize: '26px', marginBottom: 10 }}>{salesEstimate?.name}</h2>
          </Grid>
          <Grid item>{downloadButton}</Grid>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item>
          <InformationField label="Customer" value={salesEstimate?.customer?.name} />
        </Grid>
        <Grid item>
          <InformationField label="Salesperson" value={salesEstimate?.owner_name} />
        </Grid>
        <Grid item>
          <InformationField label="Last Updated" value={salesEstimate?.updated_at} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EstimateInfoHeader;
