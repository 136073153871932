import React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import { SalesEstimate } from '../model';

interface EstimateSummaryProps {
  salesEstimate: SalesEstimate;
}

const EstimateSummary: React.FunctionComponent<EstimateSummaryProps> = (props) => {
  let { salesEstimate } = props;

  const rows = [
    { label: 'Total Costs', value: salesEstimate?.total_costs },
    { label: 'Tax', value: salesEstimate?.tax_amount, isGrayRow: true },
    { label: 'Margin', value: salesEstimate?.gross_profit_display },
    { label: 'Rebates', value: salesEstimate?.total_rebates, isGrayRow: true },
    { label: 'Sale Price', value: salesEstimate?.sale_price, isFinalRow: true },
  ];

  const EstimateRow = (row) => (
    <>
      <Grid
        item
        xs={6}
        style={row.isGrayRow ? { backgroundColor: '#F0F0F0', padding: '3px 8px' } : { padding: '3px 8px' }}
      >
        <Typography variant="h6">{row.label}</Typography>
      </Grid>
      <Grid
        item
        xs={6}
        style={{
          padding: '3px 8px',
          textAlign: 'right',
          backgroundColor: row.isGrayRow ? '#F0F0F0' : undefined,
          color: row.isFinalRow ? 'green' : 'undefined',
          fontSize: row.isFinalRow ? 22 : 20,
          fontWeight: row.isFinalRow ? 'bold' : undefined,
        }}
      >
        {row.value}
      </Grid>
    </>
  );

  return (
    <Card>
      <CardContent>
        <h3 style={{ marginBottom: 10 }}>Estimate Summary</h3>
        <Grid container style={{ paddingLeft: 0, paddingRight: 30 }}>
          {rows.map((row, index) => (
            <EstimateRow key={index} {...row} />
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default EstimateSummary;
