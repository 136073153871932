import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Grid, Popper } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { EstimateDetailsContext } from '../../estimateDetails/contexts/EstimateDetailsContext';
import InformationField from '../../form/components/InformationField';
import ReplacementEquipmentCard from './ReplacementEquipmentCard';

import axios from '../../utils/axios.utils';
import ReplacementEquipmentFilterBar from './ReplacementEquipmentFilterBar';

import SystemAccessoryRecordFormRow from './SystemAccessoryRecordFormRow';

import { EquipmentCostRecord } from '../../estimateDetails/model';

// Show actual values in Dialog modal?

// Make sure the info popup still works

const filterChoices = {
  manufacturer_choices: [
    { label: 'Carrier', value: 'carrier' },
    { label: 'Trane', value: '2' },
  ],
  tons_choices: [
    { label: '3-5', value: 'small' },
    { label: '6-12.5', value: 'medium' },
    { label: '15-25', value: 'large' },
    { label: '25+', value: 'xlarge' },
  ],
  heating_choices: [
    { label: 'Yes', value: 'yes' },
    { label: 'No', value: 'no' },
  ],
  orientation_choices: [
    { label: 'Horizontal', value: 'horizontal' },
    { label: 'Vertical', value: 'vertical' },
    { label: 'Combo', value: 'combo' },
  ],
};

const ReplacementEquipmentForm = (props) => {
  let history = useHistory();
  let { salesEstimateId } = props;

  let { salesEstimate, unsavedChanges, setUnsavedChanges } = useContext(EstimateDetailsContext);

  let [assetDetailsModalOpen, setAssetDetailsModalOpen] = useState(false);
  let [modalDetails, setModalDetails] = useState([]);
  let [equipmentCostRecords, setEquipmentCostRecords] = useState<EquipmentCostRecord[]>();
  let [systemAccessoryRecords, setSystemAccessoryRecords] = useState([]);

  const handleClose = () => {
    setAssetDetailsModalOpen(false);
    setModalDetails([]);
  };

  let addSystemAccessoryRecord = async () => {
    let response: any = await axios.post('/system_accessory_records', { sales_estimate_id: salesEstimateId });
    setSystemAccessoryRecords(response.data.result);
  };

  useEffect(() => {
    setEquipmentCostRecords(salesEstimate.equipment_cost_records);
    setSystemAccessoryRecords(salesEstimate.system_accessory_records);
  }, [salesEstimate]);

  const handleUnsavedChanges = (id, hasChanges) => {
    setUnsavedChanges((prev) => {
      const updatedChanges = { ...prev, [id]: hasChanges };
      if (!hasChanges) delete updatedChanges[id]; // Remove the ID if changes are saved
      return updatedChanges;
    });
  };

  return (
    <Grid container>
      <Dialog open={assetDetailsModalOpen} onClose={handleClose}>
        <DialogTitle>Additional Details</DialogTitle>
        <DialogContent>
          <div className="expanded-row" style={{ width: '100%' }}>
            <Grid container spacing={3}>
              {modalDetails?.map((key_value) => (
                <Grid item xs={4}>
                  <InformationField label={key_value.label} value={key_value.value || 'Unknown'} />
                </Grid>
              ))}
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Grid item xs={12}>
        <ReplacementEquipmentFilterBar filterChoices={filterChoices} />
      </Grid>
      {equipmentCostRecords?.map((equipment_cost_record) => (
        <Grid key={equipment_cost_record.id} item xs={12} style={{ marginBottom: 15 }}>
          <ReplacementEquipmentCard
            initialEquipmentCostRecord={equipment_cost_record}
            setModalDetails={setModalDetails}
            setAssetDetailsModalOpen={setAssetDetailsModalOpen}
            handleUnsavedChanges={handleUnsavedChanges}
          />
        </Grid>
      ))}
      <Grid item xs={12}>
        <h2 style={{ fontSize: '18px', marginBottom: 10 }}>System Accessories</h2>
      </Grid>
      <Grid item xs={12}>
        {systemAccessoryRecords.map((record, index) => (
          <SystemAccessoryRecordFormRow
            key={record.id}
            initialSystemAccessoryRecord={record}
            setSystemAccessoryRecords={setSystemAccessoryRecords}
            handleUnsavedChanges={handleUnsavedChanges}
          />
        ))}
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 15 }}>
        <Button onClick={() => addSystemAccessoryRecord()} variant="contained" color="secondary">
          Add System Accessory
        </Button>
      </Grid>
      {Object.keys(unsavedChanges).length > 0 && (
        <Grid item xs={12}>
          <p style={{ color: 'red' }}>You have unsaved changes. Please save before continuing.</p>
        </Grid>
      )}
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => history.push(`/rooftop_automation/${salesEstimateId}/crane_curb`)}
          disabled={Object.keys(unsavedChanges).length > 0}
        >
          Continue
        </Button>
      </Grid>
    </Grid>
  );
};

export default ReplacementEquipmentForm;
