import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ReplacementEquipmentForm from '../components/ReplacementEquipmentForm';
import { EstimateDetailsContext } from '../../estimateDetails/contexts/EstimateDetailsContext';
import { SystemAccessoriesContext } from '../../adminSettings/contexts/SystemAccessoriesContext';
import { EstimateCostsContext } from '../contexts/EstimateCostsContext';
import SalesEstimateStepper from '../../navigation/components/SalesEstimateStepper';
import SalesEstimateLoading from '../../navigation/components/SalesEstimateLoading';
import EstimateInfoHeader from '../../estimateCustomers/components/EstimateInfoHeader';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    width: '100%',
    padding: 0,
  },
  box: {
    width: '100%',
  },
  content: {
    marginLeft: '0px',
    padding: '80px 30px',
  },
}));

const NewEquipment: React.FC = () => {
  const classes = useStyles();

  const [progress, setProgress] = React.useState<number>(100);
  const [assetsFetched, setAssetsFetched] = React.useState(false);

  const { estimateId } = useParams<{ estimateId: string }>();

  const { salesEstimate, fetchSalesEstimate, salesEstimateLoading } = React.useContext(EstimateDetailsContext);
  const { fetchEquipmentCostOptions } = React.useContext(EstimateCostsContext);
  const { fetchSystemAccessories } = React.useContext(SystemAccessoriesContext);

  React.useEffect(() => {
    if (!assetsFetched) {
      fetchSalesEstimate(estimateId);
    }
  }, [assetsFetched, estimateId]);

  React.useEffect(() => {
    fetchEquipmentCostOptions();
    fetchSystemAccessories();
  }, [estimateId]);

  return (
    !salesEstimateLoading && (
      <Box className={classes.box}>
        <SalesEstimateStepper activeStep={2} salesEstimateId={estimateId} />
        {progress != null && progress < 100 ? (
          <>
            <Box sx={{ width: '100%', textAlign: 'center', marginTop: 25 }}>
              <Typography variant="h2" sx={{ fontSize: '18px', mt: 2 }}>
                Decoding assets...
              </Typography>
            </Box>
            <br />
            <SalesEstimateLoading progress={progress} setProgress={setProgress} />
          </>
        ) : (
          <Box>
            <Grid container>
              <Grid item xs={12} md={12}>
                <EstimateInfoHeader salesEstimate={salesEstimate} />
                <br />
                <h2 style={{ fontSize: '28px', marginBottom: 10 }}>Select Replacement Equipment</h2>
                <br />
                <ReplacementEquipmentForm salesEstimateId={estimateId} />
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
    )
  );
};

export default NewEquipment;
