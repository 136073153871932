import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CraneAndCurbForm from '../components/CraneAndCurbForm';
import { GOOGLE_API_KEY } from '../../utils/env';
import SalesEstimateStepper from '../../navigation/components/SalesEstimateStepper';
import SalesEstimateLoading from '../../navigation/components/SalesEstimateLoading';
import { EstimateDetailsContext } from '../../estimateDetails/contexts/EstimateDetailsContext';
import { EstimateCostsContext } from '../contexts/EstimateCostsContext';
import { LoadScript } from '@react-google-maps/api';
import EstimateInfoHeader from '../../estimateCustomers/components/EstimateInfoHeader';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    width: '100%',
    padding: 0,
  },
  box: {
    width: '100%',
  },
  content: {
    marginLeft: '0px',
    padding: '80px 30px',
  },
}));

const NewCraneAndCurb: React.FC = () => {
  const classes = useStyles();

  const { estimateId } = useParams<{ estimateId: string }>();
  const { salesEstimate, fetchSalesEstimate } = React.useContext(EstimateDetailsContext);
  const { fetchCraneDetails } = React.useContext(EstimateCostsContext);

  React.useEffect(() => {
    fetchSalesEstimate(estimateId);
  }, []);

  React.useEffect(() => {
    fetchCraneDetails(estimateId);
  }, []);

  return (
    <Box className={classes.box}>
      <SalesEstimateStepper activeStep={3} salesEstimateId={estimateId} />
      <Box>
        <Grid container>
          <Grid item xs={12} xl={10}>
            <EstimateInfoHeader salesEstimate={salesEstimate} />
            <br />
            <h2 style={{ fontSize: '28px', marginBottom: 10 }}>Crane & Curb Details</h2>
            <br />
            <LoadScript googleMapsApiKey={GOOGLE_API_KEY}>
              <CraneAndCurbForm salesEstimateId={estimateId} />
            </LoadScript>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default NewCraneAndCurb;
