import React, { useState, Dispatch, SetStateAction, useEffect, useContext } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import SelectInput from '../../form/components/SelectInput';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { EstimateDetailsContext } from '../../estimateDetails/contexts/EstimateDetailsContext';
import { EstimateCostsContext } from '../contexts/EstimateCostsContext';
import InformationField from '../../form/components/InformationField';
import { InstallationCostRecord } from '../../estimateDetails/model';
import axios from '../../utils/axios.utils';

interface EditInstallationCostModalProps {}

const EditInstallationCostModal: React.FC<EditInstallationCostModalProps> = (props) => {
  const { salesEstimate, setSalesEstimate, costRecordEditModalOpen, setCostRecordEditModalOpen, costRecordToEdit } =
    useContext(EstimateDetailsContext);
  let { installationCosts } = useContext(EstimateCostsContext);

  let [recordFormValues, setRecordFormValues] = useState<InstallationCostRecord>();
  let [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  let [descriptionOptions, setDescriptionOptions] = useState([]);
  let [totalCost, setTotalCost] = useState(0);

  let assetChoices = salesEstimate.sales_estimate_assets?.flatMap((asset) => ({
    label: asset.asset_number,
    value: asset.id,
  }));

  assetChoices?.push({ label: 'None', value: 'None' });

  useEffect(() => {
    setRecordFormValues(costRecordToEdit);
    setDescriptionOptions(
      installationCosts.filter((option: any) => costRecordToEdit.primary_category === option.primary_category),
    );
  }, [costRecordToEdit]);

  useEffect(() => {
    let total = parseFloat(recordFormValues?.job_units) * parseFloat(recordFormValues?.unit_cost) || 0;
    setTotalCost(total);
  }, [recordFormValues]);

  let handleSaveClicked = async () => {
    let response: any = await axios.put(`/installation_cost_records/${recordFormValues?.id}`, {
      installation_cost_record: recordFormValues,
    });
    setSalesEstimate(response.data.result);
    setCostRecordEditModalOpen(false);
  };

  let handleRemove = async () => {
    let response: any = await axios.delete(`/installation_cost_records/${recordFormValues.id}`);
    setSalesEstimate(response.data.result);
    setCostRecordEditModalOpen(false);
    setDeleteConfirmOpen(false);
  };

  return (
    <Dialog open={costRecordEditModalOpen} onClose={() => setCostRecordEditModalOpen(false)} fullWidth maxWidth="md">
      <DialogTitle>Edit Installation Cost Record</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item md={3} xs={6} style={{ marginBottom: 15 }}>
            <SelectInput
              label="Asset"
              value={recordFormValues?.sales_estimate_asset_id || 'None'}
              choices={assetChoices}
              onChange={(event: any) => {
                setRecordFormValues({ ...recordFormValues, sales_estimate_asset_id: event.target.value });
              }}
            />
          </Grid>
          <Grid item xs={6} style={{ marginBottom: 15 }}>
            <SelectInput
              label="Description"
              value={recordFormValues?.installation_cost_id}
              onChange={(event: any) => {
                setRecordFormValues({ ...recordFormValues, installation_cost_id: event.target.value });
              }}
              choices={descriptionOptions}
            />
          </Grid>
          <Grid item xs={0} md={3} />
          <Grid item xs={4} md={4}>
            <TextField
              label="Unit Cost"
              variant="outlined"
              value={recordFormValues?.unit_cost}
              fullWidth
              style={{ backgroundColor: 'white' }}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              onChange={(event) => setRecordFormValues({ ...recordFormValues, unit_cost: event.target.value })}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Units"
              value={recordFormValues?.job_units}
              style={{ backgroundColor: 'white' }}
              onChange={(event) => {
                setRecordFormValues({ ...recordFormValues, job_units: event.target.value });
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <InformationField label="Total Cost" value={`$ ${totalCost}`} align="right" />
          </Grid>
          <Grid item xs={4} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            <Button variant="contained" onClick={handleSaveClicked} style={{ height: '50px' }}>
              Save
            </Button>
            <Button
              variant="contained"
              color="warning"
              onClick={() => setDeleteConfirmOpen(true)}
              style={{ height: '50px', marginLeft: 10 }}
            >
              Delete
            </Button>
          </Grid>
          {deleteConfirmOpen && (
            <Grid item xs={12} style={{ padding: '2%' }}>
              <Card style={{ padding: '2%', backgroundColor: 'white' }}>
                <Grid container>
                  <Grid item xs={4}>
                    <Typography variant="h6">Delete this Line Item?</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Button variant="contained" color="warning" onClick={handleRemove}>
                      Confirm
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      style={{ backgroundColor: 'gray' }}
                      onClick={() => setDeleteConfirmOpen(false)}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default EditInstallationCostModal;
