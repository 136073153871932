import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Card, CardContent, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AuthenticationContext } from '../../../homeModules/auth/contexts/AuthenticationContext';
import DataTable from '../../dataTable/DataTable/DataTable';
import RecentEstimatesFilterBar from '../components/RecentEstimatesFilterBar';
import { EstimateDetailsContext } from '../../estimateDetails/contexts/EstimateDetailsContext';
import RecentEstimatesDeleteModal from '../components/RecentEstimatesDeleteModal';
import RecentEstimatesCopyModal from '../components/RecentEstimatesCopyModal';
import { SalesEstimateListItem } from '../model';
import { salesEstimateColumns } from '../constants/salesEstimateColumns';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    width: '100%',
    padding: 0,
  },
  box: {
    width: '100%',
  },
  content: {
    marginLeft: '0px',
    padding: '80px 30px',
  },
}));

const RecentEstimates: React.FunctionComponent = () => {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useContext(AuthenticationContext);

  const [filterValues, setFilterValues] = useState<any>({ user_id_eq: user?.id });

  const {
    salesEstimates,
    fetchSalesEstimates,
    setRecentEstimatesDeleteModalOpen,
    setRecentEstimatesCopyModalOpen,
    setFocusEstimate,
  } = useContext(EstimateDetailsContext);

  const handleDelete = (row: SalesEstimateListItem) => {
    setFocusEstimate(row as any);
    setRecentEstimatesDeleteModalOpen(true);
  };

  const handleDuplicate = (row: SalesEstimateListItem) => {
    setFocusEstimate(row as any);
    setRecentEstimatesCopyModalOpen(true);
  };

  const columns = salesEstimateColumns(handleDelete, handleDuplicate);

  return (
    <Box className={classes.box}>
      <RecentEstimatesDeleteModal afterDelete={fetchSalesEstimates} />
      <RecentEstimatesCopyModal />
      <Box>
        <Grid justifyContent="space-between" container spacing={3}>
          <Grid item>
            <Typography variant="h4" component="h4">
              Recent Estimates
            </Typography>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={() => history.push('/rooftop_automation/customers/search')}>
              New Estimate +
            </Button>
          </Grid>
        </Grid>
        <br />
        <RecentEstimatesFilterBar filterValues={filterValues} setFilterValues={setFilterValues} />
        <br />
        <Card>
          <CardContent>
            <DataTable
              title=""
              columns={columns}
              data={salesEstimates}
              onRowClicked={(row) => history.push(`/rooftop_automation/${row.id}/review`)}
              striped
              dense
              highlightOnHover
              pointerOnHover
              noHeader
            />
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default RecentEstimates;
