import React from 'react';
import { makeStyles } from '@mui/styles';
import { AppBar, Menu, MenuItem, Link, Grid } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Snackbar from '@mui/material/Snackbar';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { BrowserUtils } from '@azure/msal-browser';
import { AuthenticationContext } from './auth/contexts/AuthenticationContext';

const useStyles = makeStyles((theme: any) => ({
  root: {
    boxShadow: '0 1px 6px 0 rgb(32 33 36 / 28%)',
  },
  grow: {
    flexGrow: 1,
  },
  toolbar: {
    backgroundColor: '#fff',
  },
  menuButton: {
    marginRight: 2,
  },
  inputRoot: {
    color: 'inherit',
    border: '1px solid #ccc',
  },
  sectionDesktop: {
    display: 'none',
    flexGrow: 1,

    '@media (min-width: 960px)': {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    flexGrow: 1,

    '@media (min-width: 960px)': {
      display: 'none',
    },
  },
  logoContainer: {
    '@media (max-width: 768px)': {
      display: 'none',
    },
  },
}));

interface UserTopNavigationprops {
  mobileNavigationActive: boolean;
  setMobileNavigationActive: any;
}

const UserTopNavigation: React.FunctionComponent<UserTopNavigationprops> = (props) => {
  const { mobileNavigationActive, setMobileNavigationActive } = props;
  const classes = useStyles();
  const { logout } = React.useContext(AuthenticationContext);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const [browserCacheNotification, setBrowserCacheNotification] = React.useState(false);

  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const userAccount = accounts[0];

  React.useEffect(() => {
    if (userAccount?.username) {
      sessionStorage.setItem('userEmail', userAccount.username || 'admin@tudi.com');
    }
  }, [userAccount]);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClearBrowserCache = () => {};

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuId = 'primary-search-account-menu';

  const handleLogOut = () => {
    logout();
    instance.logoutRedirect({
      account: instance.getActiveAccount(),
      postLogoutRedirectUri: 'https://sales.tudi.com/login',
    });
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>
        <Link style={{ color: '#000', textDecoration: 'none' }} href="/profile">
          My Account
        </Link>
      </MenuItem>

      <MenuItem onClick={handleLogOut}>Logout</MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <Snackbar
        open={browserCacheNotification}
        autoHideDuration={6000}
        onClose={() => setBrowserCacheNotification(false)}
        message="Your browser cache has been successfully cleared."
      />
      <AppBar position="fixed" className={classes.root}>
        <Toolbar className={classes.toolbar}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <button
                type="button"
                className="Polaris-TopBar__NavigationIcon"
                aria-label="Toggle menu"
                onClick={() => setMobileNavigationActive(true)}
                style={{ marginTop: 7 }}
              >
                <span className="Polaris-Icon">
                  <svg viewBox="0 0 20 20" className="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
                    <path d="M19 11H1a1 1 0 0 1 0-2h18a1 1 0 1 1 0 2zm0-7H1a1 1 0 0 1 0-2h18a1 1 0 1 1 0 2zm0 14H1a1 1 0 0 1 0-2h18a1 1 0 0 1 0 2z" />
                  </svg>
                </span>
              </button>
              <Grid container>
                <Grid item className={classes.logoContainer}>
                  <a href={'/home'}>
                    <img
                      alt="logo"
                      src={
                        'https://www.tudi.com/wp-content/themes/boxpress/assets/img/dist/branding/tudi-mechanical-logo@2x.png'
                      }
                      style={{ height: '40px', marginTop: 15 }}
                    />
                  </a>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <div className={classes.grow} />
              <div className={classes.sectionDesktop}>
                <div>
                  <Typography style={{ lineHeight: '65px', color: 'black' }}>
                    {userAccount?.username || 'admin@tudi.com'} ({isAuthenticated ? 'Azure AD' : 'Internal'})
                  </Typography>
                </div>
                <IconButton edge="end" color="primary" onClick={handleProfileMenuOpen}>
                  <AccountCircle style={{ fontSize: '2.2rem' }} />
                </IconButton>
              </div>
              <div className={classes.sectionMobile}>
                <IconButton edge="end" color="primary" onClick={handleProfileMenuOpen}>
                  <AccountCircle style={{ fontSize: '2.2rem' }} />
                </IconButton>
              </div>
            </Grid>
          </Grid>
          {renderMenu}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default UserTopNavigation;
