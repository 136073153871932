import React, { useState, useContext, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextInput from '../../form/components/TextInput';
import { CurbAdapter } from '../../estimateDetails/model';
import { SalesEstimateAsset } from '../../estimateDetails/model';
import { EstimateCostsContext } from '../contexts/EstimateCostsContext';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useDebounce } from 'use-debounce';
import axios from '../../utils/axios.utils';
import { SnackBarContext } from '../../form/contexts/SnackBarContext';

interface CurbAdapterSubFormProps {
  curbAdapter: CurbAdapter;
  salesEstimateAsset: SalesEstimateAsset;
}

const CurbAdapterSubForm: React.FC<CurbAdapterSubFormProps> = (props) => {
  const { curbAdapter, salesEstimateAsset } = props;

  const { updateCurbAdapter } = useContext(EstimateCostsContext);
  const { showSuccessSnackBar } = React.useContext(SnackBarContext);

  const [formValues, setFormValues] = useState<CurbAdapter>({
    ...curbAdapter,
  });

  const [micrometlOptions, setMicrometlOptions] = useState<{
    previous: { label: string; value: any }[];
    replacement: { label: string; value: any }[];
  }>({
    previous: [],
    replacement: [],
  });

  const [debouncedSearchTermExisting] = useDebounce(formValues.previous_asset_lookup_name, 1500);
  const [debouncedSearchTermReplacement] = useDebounce(formValues.replacement_asset_lookup_name, 1500);

  const fetchMicroMetlInfo = async (whichUnit: 'previous' | 'replacement', query: string) => {
    try {
      const response: any = await axios.get('/sales_estimate_assets/0/curb_adapters/new', {
        params: { query: query, unit_type: whichUnit, curb_adapter_id: curbAdapter.id },
      });
      setMicrometlOptions((prevOptions) => ({
        ...prevOptions,
        [whichUnit]: response.data.micrometl_options,
      }));
    } catch (error) {
      console.error('Error fetching Micrometl info:', error);
    }
  };

  const fetchCurbAdapterPartName = async () => {
    try {
      let response = await axios.get('/sales_estimate_assets/0/curb_adapters/part_name', {
        params: { ...formValues, curb_adapter_id: curbAdapter.id },
      });
      let updatedFormValues = { ...formValues, micrometl_part_name: response.data };

      if (response.data === 'No Curb Adapter Required') {
        updatedFormValues.fits_existing = 'true';
      }

      setFormValues(updatedFormValues);
    } catch (error) {
      console.error('Error fetching part name:', error);
    }
  };

  const handleCurbValueChange = (field: string, value: any) => {
    const updatedCurbFormValues = { ...formValues, [field]: value };
    setFormValues(updatedCurbFormValues);
  };

  useEffect(() => {
    // if (formValues.selected_micrometl_id_replacement != null && formValues.selected_micrometl_id_previous != null) {
    fetchCurbAdapterPartName();
    // }
  }, [formValues.selected_micrometl_id_previous, formValues.selected_micrometl_id_replacement]);

  useEffect(() => {
    if (debouncedSearchTermExisting && debouncedSearchTermExisting.length >= 3) {
      fetchMicroMetlInfo('previous', debouncedSearchTermExisting);
    }
  }, [debouncedSearchTermExisting]);

  useEffect(() => {
    if (debouncedSearchTermReplacement && debouncedSearchTermReplacement.length >= 3) {
      fetchMicroMetlInfo('replacement', debouncedSearchTermReplacement);
    }
  }, [debouncedSearchTermReplacement]);

  const handleCurbSubmit = (salesEstimateAssetId) => {
    updateCurbAdapter(salesEstimateAssetId, formValues, () => showSuccessSnackBar('Curb adapter saved successfully.'));
  };

  const renderUnitSection = (unitType: 'previous' | 'replacement', label: string) => {
    const selectedOption =
      micrometlOptions[unitType].find((option) => option.value === formValues[`selected_micrometl_id_${unitType}`]) ||
      null;

    return (
      <Card style={{ padding: 15 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>{`${label} Unit Model Number: ${salesEstimateAsset[`model_number_${unitType}`]}`}</Typography>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: 15 }}>
            <Autocomplete
              freeSolo
              disabled={!!formValues?.fits_existing}
              options={micrometlOptions[unitType]}
              getOptionLabel={(option: any) => option.label}
              inputValue={formValues[`${unitType}_asset_lookup_name`] || ''}
              onInputChange={(event, newInputValue) => {
                handleCurbValueChange(`${unitType}_asset_lookup_name`, newInputValue);
              }}
              value={selectedOption}
              onChange={(event, newValue: any) => {
                handleCurbValueChange(`selected_micrometl_id_${unitType}`, newValue ? newValue.value : null);
              }}
              renderInput={(params) => (
                <TextField {...params} label={`Micrometl Options for ${label} Unit`} variant="outlined" />
              )}
            />
          </Grid>
        </Grid>
      </Card>
    );
  };

  return (
    <Grid container style={{ backgroundColor: '#dcf5e6', padding: 15, borderRadius: 15 }}>
      <Grid item xs={12} style={{ marginBottom: 20 }}>
        <Typography style={{ fontSize: 22 }}>
          <strong>Asset: </strong> {salesEstimateAsset.asset_number}
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 10, display: 'flex', justifyContent: 'center' }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={!!formValues?.fits_existing}
              onChange={(event) => handleCurbValueChange('fits_existing', event.target.checked)}
              name="fits_existing"
              color="primary"
            />
          }
          label="New Unit Fits Existing Curb"
        />
      </Grid>

      <Grid item xs={12} style={{ marginBottom: 15 }}>
        {renderUnitSection('previous', 'Existing')}
      </Grid>

      <Grid item xs={12} style={{ marginBottom: 15 }}>
        {renderUnitSection('replacement', 'Replacement')}
      </Grid>

      <Grid item xs={12} style={{ marginBottom: 15 }}>
        <Card style={{ padding: 15 }}>
          <Grid container>
            <Grid item xs={12} style={{ marginBottom: 10 }}>
              <Typography style={{ fontSize: 16 }}>
                <strong>Curb Adapter Part Name: </strong>
                {formValues?.micrometl_part_name}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 15 }}>
              <TextInput
                label="Curb Adapter Cost"
                value={formValues?.cost}
                disabled={!!formValues?.fits_existing}
                valueChanged={(value) => handleCurbValueChange('cost', value)}
                shrinkLabel
                currency
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                label="Submittal URL"
                value={
                  formValues?.micrometl_part_name?.includes('No Curb')
                    ? ''
                    : `https://micrometlwebsite.blob.core.windows.net/micrometlwebsiteimages/${formValues?.micrometl_part_name}.pdf`
                }
                disabled={!!formValues?.fits_existing}
                valueChanged={(value) => handleCurbValueChange('submittal_url', value)}
                shrinkLabel
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>

      <Grid item xs={11} md={5} style={{ marginBottom: 10 }}>
        <Button variant="contained" onClick={() => handleCurbSubmit(salesEstimateAsset.id)} type="button">
          Save Curb Details
        </Button>
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={11} md={5}>
        <Button
          variant="contained"
          onClick={() =>
            window.open(
              `https://micrometlwebsite.blob.core.windows.net/micrometlwebsiteimages/${formValues?.micrometl_part_name}.pdf`,
              '_blank',
            )
          }
          type="button"
        >
          Preview Document
        </Button>
      </Grid>
    </Grid>
  );
};

export default CurbAdapterSubForm;
