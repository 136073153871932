/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image, Font } from '@react-pdf/renderer';
import { SalesEstimate, ProposalDetail } from '../../estimateDetails/model';
import TudiLetterhead from '../../../../src/assets/TudiLetterheadTop.jpeg';
import { termsAndConditions } from './termsAndConditions';

Font.register({ family: 'Open Sans', src: '/assets/fonts/OpenSans-Medium.ttf' });
Font.register({ family: 'Open Sans Italic', src: '/assets/fonts/OpenSans-Italic.ttf' });
Font.register({ family: 'Open Sans Bold', src: '/assets/fonts/OpenSans-Bold.ttf' });
Font.register({ family: 'Alex Brush', src: '/assets/fonts/AlexBrush-Regular.ttf' });

type Props = {
  salesEstimate: SalesEstimate;
  proposalDetail: ProposalDetail;
};

const today = new Date();

const formattedDate = today.toLocaleDateString('en-US', {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
});

// These styles were copied from the CSIP Generator app.
// Many of them can probably be deleted after we have the appearance of the PDF finalized.
const styles = StyleSheet.create({
  bulletPoint: {
    width: 10,
    textAlign: 'right',
  },
  page: {
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
  },
  column: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '48%',
  },
  contentContainer: {
    paddingLeft: '10%',
    paddingRight: '10%',
    paddingTop: '4%',
  },
  body: {
    fontFamily: 'Open Sans',
    fontSize: 10,
    height: '100%',
    width: '80%',
    marginBottom: 10,
  },
  italicBody: {
    fontFamily: 'Open Sans Italic',
    fontSize: 10,
    height: '100%',
    width: '80%',
    marginBottom: 10,
  },
  subListItem: {
    fontFamily: 'Open Sans',
    flexDirection: 'row',
    marginTop: 3,
    marginLeft: 25,
    fontSize: 9,
  },
  signatureInfo: {
    flexDirection: 'row',
    marginTop: 3,
    marginBottom: 3,
    fontSize: 10,
    fontFamily: 'Open Sans',
  },
  signLine: {
    flexDirection: 'row',
    marginTop: 3,
    marginBottom: -3,
    fontSize: 10,
    fontFamily: 'Open Sans',
  },
  signatureName: {
    flexDirection: 'row',
    marginTop: 3,
    marginBottom: 3,
    fontSize: 20,
    fontFamily: 'Alex Brush',
  },
  termsAndConditionsView: {
    marginBottom: 10,
    flexDirection: 'column', // Stack children vertically
    paddingBottom: 5, // Padding at the bottom
  },
  termsAndConditions: {
    fontFamily: 'Open Sans',
    fontSize: 9,
    textAlign: 'justify',
  },
  columnContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  costLabelColumn: {
    width: '48%',
    marginLeft: '20%',
  },
  costValueColumn: {
    width: '18%',
    marginRight: '20%',
  },
  flexCenterHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    // width: '100%',
    marginBottom: 0,
    fontFamily: 'Open Sans',
  },
  bulletSectionContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  bulletSectionHeader: {
    marginBottom: 0,
    fontFamily: 'Open Sans Bold',
    fontSize: 10,
  },
  footer: { textAlign: 'right', marginBottom: 15, color: 'white', fontSize: 8, padding: 5 },
  footerContainer: {
    position: 'absolute',
    bottom: 10,
    width: '100%',
    backgroundColor: 'black',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: 10,
    paddingRight: 10,
  },
  header: {},
  headerFooterText: { fontSize: 10, fontWeight: 700, textTransform: 'uppercase', fontFamily: 'Open Sans' },
  customerAndContactText: { fontSize: 10, fontWeight: 700, fontFamily: 'Open Sans' },
  horizontalRule: {
    width: 235,
    height: 3,
    left: 278,
    top: 4,
    position: 'absolute',
  },
  letterheadImage: {
    width: '100%',
    height: 50,
  },
  listItem: {
    flexDirection: 'row',
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 20,
    fontSize: 10,
    fontFamily: 'Open Sans',
    lineHeight: 0.75,
  },
  listItemContainer: {
    marginTop: 0,
    marginBottom: 4,
  },
  subListItemContainer: {
    marginLeft: 16,
    marginBottom: -5,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  sectionHeader: {
    display: 'flex',
    fontFamily: 'Raleway',
    padding: '0 50px',
  },
  pageFooter: {
    backgroundColor: 'black',
  },
  pageFooterText: {
    color: 'white',
    fontSize: 10,
    textAlign: 'center',
    fontFamily: 'Open Sans',
  },
  rowContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    maxHeight: 150,
  },
});

const FooterComponent = (currentPage: number, pageCount: number) => {
  return (
    <View style={styles.footerContainer} fixed>
      <Text style={styles.footer}>343 Munson Ave.</Text>
      <Text style={styles.footer}>McKees Rocks, PA, 15136</Text>
      <Text style={styles.footer}>P: 412-771-4100</Text>
      <Text style={styles.footer}>www.tudi.com</Text>
      <Text style={styles.footer}>
        Page {currentPage} | {pageCount}
      </Text>
    </View>
  );
};

function EstimatePDF(props: Props) {
  const { salesEstimate, proposalDetail } = props;

  let warrantyItems = proposalDetail?.warranty_items;
  let scopeOfWorkItems = proposalDetail?.scope_of_work_items;
  let exclusionItems = proposalDetail?.exclusion_items;

  return (
    <Document>
      <Page size="LETTER" style={styles.page}>
        <Image src={TudiLetterhead} style={styles.letterheadImage} />
        <View style={styles.contentContainer} fixed>
          <View style={styles.flexCenterHeader} fixed>
            <Text style={styles.headerFooterText}>{formattedDate}</Text>
            <Text style={styles.headerFooterText}>Quote Number {salesEstimate?.quote_number}</Text>
          </View>
          <View style={styles.flexCenterHeader} fixed>
            <Text style={styles.body}></Text>
          </View>
          <View style={styles.rowContainer}>
            <View style={styles.column}>
              <Text style={styles.customerAndContactText}>{salesEstimate?.contact_name}</Text>
              <Text style={styles.customerAndContactText}>{salesEstimate?.customer?.name}</Text>
              <Text style={styles.customerAndContactText}>{salesEstimate?.contact?.address1}</Text>
              <Text style={styles.customerAndContactText}>
                {salesEstimate?.contact?.city}, {salesEstimate?.contact?.state} {salesEstimate?.contact?.zip}
              </Text>
            </View>
            <View style={styles.column}>
              <Text style={styles.customerAndContactText}>Job Site:</Text>
              <Text style={styles.customerAndContactText}>{salesEstimate?.site?.name}</Text>
              <Text style={styles.customerAndContactText}>{salesEstimate?.site?.address1}</Text>
              <Text style={styles.customerAndContactText}>
                {salesEstimate?.site?.city}, {salesEstimate?.site?.state} {salesEstimate?.site?.zip}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.contentContainer} fixed>
          <View style={styles.flexCenterHeader} fixed>
            <Text style={styles.bulletSectionHeader}>RE: {salesEstimate?.name},</Text>
          </View>
          <View style={styles.flexCenterHeader} fixed>
            <Text style={styles.body}></Text>
          </View>
          <View style={styles.flexCenterHeader} fixed>
            <Text style={styles.body}>{proposalDetail?.greeting_paragraph}</Text>
          </View>
          {scopeOfWorkItems?.length > 0 && (
            <View style={styles.bulletSectionContainer} fixed>
              <Text style={styles.bulletSectionHeader}>The following is included in our scope of work:</Text>
            </View>
          )}
          {scopeOfWorkItems?.map((item) => (
            <View style={styles.listItemContainer} fixed key={item.id}>
              <Text style={styles.listItem}>• {item.description}</Text>
              {item.sub_bullets?.map((subItem) => (
                <View style={styles.subListItemContainer} fixed key={subItem.id}>
                  <Text style={styles.subListItem}>o {subItem.description}</Text>
                </View>
              ))}
            </View>
          ))}

          {warrantyItems?.length > 0 && (
            <View style={styles.bulletSectionContainer} fixed>
              <Text style={styles.bulletSectionHeader}>Warranty:</Text>
            </View>
          )}
          {warrantyItems?.map((item) => (
            <View style={styles.listItemContainer} fixed key={item.id}>
              <Text style={styles.listItem}>• {item.description}</Text>
              {item.sub_bullets?.map((subItem) => (
                <View style={styles.subListItemContainer} fixed key={subItem.id}>
                  <Text style={styles.subListItem}>o {subItem.description}</Text>
                </View>
              ))}
            </View>
          ))}

          {exclusionItems?.length > 0 && (
            <View style={styles.bulletSectionContainer} fixed>
              <Text style={styles.bulletSectionHeader}>Exclusions:</Text>
            </View>
          )}
          {exclusionItems?.map((item) => (
            <View style={styles.listItemContainer} fixed key={item.id}>
              <Text style={styles.listItem}>• {item.description}</Text>
              {item.sub_bullets?.map((subItem) => (
                <View style={styles.subListItemContainer} fixed key={subItem.id}>
                  <Text style={styles.subListItem}>o {subItem.description}</Text>
                </View>
              ))}
            </View>
          ))}
          {/* Temp hack for a blank space */}
          <View style={styles.flexCenterHeader} fixed>
            <Text style={styles.body}></Text>
          </View>
          <View style={styles.flexCenterHeader} fixed>
            <Text style={styles.bulletSectionHeader}>
              Total Installed Cost: &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp;
              &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp;
              {salesEstimate?.sale_price}
            </Text>
          </View>
          <View style={styles.flexCenterHeader} fixed>
            <Text style={styles.body}></Text>
          </View>
          <View style={styles.flexCenterHeader} fixed>
            <Text style={styles.italicBody}>{proposalDetail?.terms}</Text>
          </View>
          <View style={styles.flexCenterHeader} fixed>
            <Text style={styles.body}>{proposalDetail?.closing_paragraph}</Text>
          </View>
          <View style={styles.rowContainer}>
            <View style={styles.column}>
              <Text style={styles.signatureInfo}>Sincerely,</Text>
              <Text style={styles.signatureName}>{proposalDetail?.signature}</Text>
              <Text style={styles.signatureInfo}>{proposalDetail?.signature}</Text>
              <Text style={styles.signatureInfo}>{proposalDetail?.title}</Text>
            </View>
            <View style={styles.column}>
              <Text style={styles.signatureInfo}>Agreed and Accepted By:</Text>
              <Text style={styles.signatureInfo}></Text>
              <Text style={styles.signLine}>______________________________________________</Text>
              <Text style={styles.signatureInfo}>Authorized Signature</Text>
              <Text style={styles.signatureInfo}></Text>
              <Text style={styles.signLine}>______________________________________________</Text>
              <Text style={styles.signatureInfo}>Date</Text>
            </View>
          </View>
        </View>
        {FooterComponent(1, 2)}
      </Page>
      <Page size="LETTER" style={styles.page}>
        <Image src={TudiLetterhead} style={styles.letterheadImage} />
        <View style={styles.contentContainer} fixed>
          {termsAndConditions?.map((item) => (
            <View style={styles.termsAndConditionsView} fixed>
              <Text style={styles.termsAndConditions}>{item}</Text>
            </View>
          ))}
        </View>
        {FooterComponent(2, 2)}
      </Page>
    </Document>
  );
}

export default EstimatePDF;
