import Spreadsheet from 'react-spreadsheet';
import { Button, Grid, Link } from '@mui/material';
import { unitData } from '../constants/UnitData';
import { evaporatorData } from '../constants/EvaporatorData';
import { condenserData } from '../constants/CondenserData';
import { compressorData } from '../constants/CompressorData';
import { compressor1Data } from '../constants/Compressor1Data';
import { manufacturerData } from '../constants/ManufacturerData';
import { additionalData } from '../constants/AdditionalData';

const ChillerFormSpreadsheet = () => {
  return (
    <>
      <div style={{ background: '#ccc', marginLeft: -30, width: 1400 }}>
        <h3 style={{ background: '#A21C37', color: '#fff', fontWeight: 700, padding: 5 }}>Air Cooled Unit</h3>
        <Spreadsheet
          data={unitData}
          rowLabels={[
            'Chilled Water Setpoint',
            'Outside Air Temp',
            'Evap Enter Water Temp',
            'Evap Leaving Water temp',
            'Evap GPM',
            'Evap Delta T',
            'Evap Delta P',
          ]}
          columnLabels={['07-02', '07-02', '01-25']}
          className="hide-labels no-circuit-table"
        />
        <h3 style={{ background: '#A21C37', color: '#fff', fontWeight: 700, padding: 5 }}>Evaporator Details</h3>
        <Spreadsheet
          data={evaporatorData}
          rowLabels={[
            '',
            'Suction Line PSI',
            'Saturation Temp',
            'Suction Line Temp',
            'Suction Superheat',
            'Approach',
            'Delta',
            'Expansion Valve Position',
          ]}
          columnLabels={['Circuit 1', '', '', 'Circuit 2', '', '', 'Circuit 3', '', '', 'Circuit 4', '', '']}
          className="hide-labels circuit-table"
        />
        <h3 style={{ background: '#A21C37', color: '#fff', fontWeight: 700, padding: 5 }}>Condenser Details</h3>
        <Spreadsheet
          data={condenserData}
          rowLabels={['', 'Liquid Line PSI', 'Saturation Temp', 'Liquid Temp', 'Subcooling', 'Total Fan Amps']}
          columnLabels={['Circuit 1', '', '', 'Circuit 2', '', '', 'Circuit 3', '', '', 'Circuit 4', '', '']}
          className="hide-labels circuit-table"
        />
        {/* <h3 style={{ background: '#A21C37', color: '#fff', fontWeight: 700, padding: 5 }}>Compressor Details</h3>
      <Spreadsheet
        data={compressorData}
        rowLabels={[
          '',
          'Discharge Temp',
          'Discharge PSI',
          'Discharge Superheat',
          'Oil Discharge Pressure',
          'Oil PSI Net',
          'Oil Supply Temp',
          'Oil Filter Pressure Drop',
          '%RLA',
          'Slide Valve Position',
          'Starts',
          'Hours',
          'Motor Temp (Highest)',
          'Amps L1',
          'Amps L2',
          'Amps L3',
          'Volts AB',
          'Volts AC',
          'Volts BC',
        ]}
        columnLabels={['C1/C1', '', '', 'C1/C2', '', '', 'C2/C1', '', '', 'C2/C2', '', '', 'C3/C1', '', '', 'C3/C2', '', '', 'C4/C1', '', '', 'C4/C2', '', '']}
        className="hide-labels compressor-table"
      /> */}
        <h3 style={{ background: '#A21C37', color: '#fff', fontWeight: 700, padding: 5 }}>
          Compressor Details (Circuit 1)
        </h3>
        <Spreadsheet
          data={compressor1Data}
          rowLabels={[
            '',
            'Discharge Temp',
            'Discharge PSI',
            'Discharge Superheat',
            'Oil Discharge Pressure',
            'Oil PSI Net',
            'Oil Supply Temp',
            'Oil Filter Pressure Drop',
            '%RLA',
            'Slide Valve Position',
            'Starts',
            'Hours',
            'Motor Temp (Highest)',
            'Amps L1',
            'Amps L2',
            'Amps L3',
            'Volts AB',
            'Volts AC',
            'Volts BC',
          ]}
          columnLabels={['Compressor 1', '', '', 'Compressor 2', '', '']}
          className="hide-labels no-circuit-table"
        />
        <h3 style={{ background: '#A21C37', color: '#fff', fontWeight: 700, padding: 5 }}>
          Compressor Details (Circuit 2)
        </h3>
        <Spreadsheet
          data={compressor1Data}
          rowLabels={[
            '',
            'Discharge Temp',
            'Discharge PSI',
            'Discharge Superheat',
            'Oil Discharge Pressure',
            'Oil PSI Net',
            'Oil Supply Temp',
            'Oil Filter Pressure Drop',
            '%RLA',
            'Slide Valve Position',
            'Starts',
            'Hours',
            'Motor Temp (Highest)',
            'Amps L1',
            'Amps L2',
            'Amps L3',
            'Volts AB',
            'Volts AC',
            'Volts BC',
          ]}
          columnLabels={['Compressor 1', '', '', 'Compressor 2', '', '']}
          className="hide-labels no-circuit-table"
        />
        <h3 style={{ background: '#A21C37', color: '#fff', fontWeight: 700, padding: 5 }}>
          Compressor Details (Circuit 3)
        </h3>
        <Spreadsheet
          data={compressor1Data}
          rowLabels={[
            '',
            'Discharge Temp',
            'Discharge PSI',
            'Discharge Superheat',
            'Oil Discharge Pressure',
            'Oil PSI Net',
            'Oil Supply Temp',
            'Oil Filter Pressure Drop',
            '%RLA',
            'Slide Valve Position',
            'Starts',
            'Hours',
            'Motor Temp (Highest)',
            'Amps L1',
            'Amps L2',
            'Amps L3',
            'Volts AB',
            'Volts AC',
            'Volts BC',
          ]}
          columnLabels={['Compressor 1', '', '', 'Compressor 2', '', '']}
          className="hide-labels no-circuit-table"
        />
        <h3 style={{ background: '#A21C37', color: '#fff', fontWeight: 700, padding: 5 }}>
          Compressor Details (Circuit 4)
        </h3>
        <Spreadsheet
          data={compressor1Data}
          rowLabels={[
            '',
            'Discharge Temp',
            'Discharge PSI',
            'Discharge Superheat',
            'Oil Discharge Pressure',
            'Oil PSI Net',
            'Oil Supply Temp',
            'Oil Filter Pressure Drop',
            '%RLA',
            'Slide Valve Position',
            'Starts',
            'Hours',
            'Motor Temp (Highest)',
            'Amps L1',
            'Amps L2',
            'Amps L3',
            'Volts AB',
            'Volts AC',
            'Volts BC',
          ]}
          columnLabels={['Compressor 1', '', '', 'Compressor 2', '', '']}
          className="hide-labels no-circuit-table"
        />
        <h3 style={{ background: '#A21C37', color: '#fff', fontWeight: 700, padding: 5 }}>Additional Details</h3>
        <Spreadsheet
          data={additionalData}
          rowLabels={['VSD Command', 'VSD% Actual ', 'DC Bus Voltage', 'Base Pate Temp']}
          columnLabels={['07-02', '07-02', '01-25']}
          className="hide-labels no-circuit-table"
        />
        <h3 style={{ background: '#A21C37', color: '#fff', fontWeight: 700, padding: 5 }}>
          Manufacturer Details (Trane)
        </h3>
        <Spreadsheet
          data={manufacturerData}
          rowLabels={['Oil Feed External Bearing', 'Oil Feed Internal Bearing', 'Winding Temp']}
          columnLabels={['07-02', '07-02', '01-25']}
          className="hide-labels no-circuit-table"
        />
        <h3 style={{ background: '#A21C37', color: '#fff', fontWeight: 700, padding: 5 }}>Comments</h3>
      </div>
      <br />
      <div style={{ marginLeft: -30 }}>
        <Button onClick={() => null} variant="contained">
          Submit
        </Button>
      </div>
    </>
  );
};

export default ChillerFormSpreadsheet;
