import React, { useState, useContext, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Box, Typography, Stepper, Step, StepLabel, Button, Grid, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ChillerFormSpreadsheet from '../components/ChillerFormSpreadsheet';
import ChillerLogHeader from '../components/ChillerLogHeader';
import { SnackBarContext } from '../../../modules/form/contexts/SnackBarContext';

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '100vh',
    width: '100%',
    padding: '20px',
  },
  stepContent: {
    marginTop: '20px',
    marginBottom: '20px',
  },
}));

const UpcomingJobDetail: React.FunctionComponent = () => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const { showSuccessSnackBar } = React.useContext(SnackBarContext);

  const saveFormProgress = () => {
    setTimeout(() => {
      showSuccessSnackBar('Form saved successfully.');
    }, 1000);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      showSuccessSnackBar('Saving form progress...');
      saveFormProgress();
    }, 60000);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  return (
    <Box className={classes.root} style={{ marginLeft: -200 }}>
      <Typography
        style={{ marginBottom: 10, color: '#333', opacity: 0.6, cursor: 'pointer', marginLeft: -30 }}
        onClick={() => history.push('/chiller_logs')}
      >
        ← Return to Upcoming Jobs
      </Typography>
      <ChillerLogHeader />
      <ChillerFormSpreadsheet />
    </Box>
  );
};

export default UpcomingJobDetail;
