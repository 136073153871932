import React from 'react';
import { IDataTableColumn } from 'react-data-table-component';
import Button from '@mui/material/Button';
import DataTable from '../../dataTable/DataTable/DataTable';
import { EstimateDetailsContext } from '../../estimateDetails/contexts/EstimateDetailsContext';
import axios from '../../utils/axios.utils';

import { costSummaryColumns } from '../constants/salesEstimateReviewColumns';

const ExpandedCostBreakdown = (row: any) => {
  const { salesEstimate, setSalesEstimate } = React.useContext(EstimateDetailsContext);
  const [isUpdatingProfit, setUpdatingProfit] = React.useState(false);

  const handleGrossProfitChange = async (costSummaryInfo, addend) => {
    setUpdatingProfit(true);

    const response: any = await axios.put(`sales_estimates/${salesEstimate?.id}/adjust_gpi_for_record`, {
      class_name: costSummaryInfo?.class_name,
      record_id: costSummaryInfo?.record_id,
      addend: addend,
    });

    setSalesEstimate(response.data.result);
    setUpdatingProfit(false);
  };

  const costSummaryColumns: IDataTableColumn<any>[] = [
    {
      name: 'Category',
      selector: 'category',
      sortable: true,
      cell: (row) => <p style={{ textTransform: 'capitalize', fontWeight: 700 }}>{row.category}</p>,
    },
    {
      name: 'Cost',
      selector: 'cost',
      sortable: true,
    },
    {
      name: 'Tax',
      selector: 'tax_amount',
      sortable: true,
    },
    {
      name: 'GP %',
      selector: 'gross_profit_integer',
      sortable: true,
      cell: (row, index) => (
        <div style={{ display: 'flex', alignItems: 'center', marginRight: -30 }}>
          <Button
            size="small"
            variant="contained"
            style={{ minWidth: '30px', marginRight: '5px' }}
            onClick={() => (isUpdatingProfit ? null : handleGrossProfitChange(row, -1))}
          >
            -
          </Button>
          <span>{row.gross_profit_integer}%</span>
          <Button
            size="small"
            variant="contained"
            style={{ minWidth: '30px', marginLeft: '5px' }}
            onClick={() => (isUpdatingProfit ? null : handleGrossProfitChange(row, 1))}
          >
            +
          </Button>
        </div>
      ),
    },
    {
      name: 'Margin',
      selector: 'gross_profit',
      sortable: true,
    },
    {
      name: 'Sale Price',
      selector: 'item_sale_price',
      sortable: true,
    },
  ];

  return (
    <div
      className="expanded-row pricing-table-alignment"
      style={{ width: '100%', border: '2px solid black', padding: 10 }}
    >
      <DataTable
        title=""
        columns={costSummaryColumns}
        data={row.data.subtypes}
        striped
        dense
        highlightOnHover
        pointerOnHover
        noHeader
      />
    </div>
  );
};

export default ExpandedCostBreakdown;
