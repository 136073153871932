import React, { useState, useEffect, useContext } from 'react';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import { EstimateDetailsContext } from '../contexts/EstimateDetailsContext';

import axios from '../../utils/axios.utils';

interface MarginDetailsProps {}

const MarginDetails: React.FC<MarginDetailsProps> = (props) => {
  let { salesEstimate, setSalesEstimate } = useContext(EstimateDetailsContext);

  const [newGrossProfitInteger, setNewGrossProfitInteger] = useState(salesEstimate?.gross_profit_integer);

  useEffect(() => {
    setNewGrossProfitInteger(salesEstimate?.gross_profit_integer);
  }, [salesEstimate]);

  const handleSaveGrossProfitInteger = async () => {
    let response: any = await axios.put(`sales_estimates/${salesEstimate?.id}/adjust_gpi_for_all`, {
      sales_estimate: { gross_profit_integer: newGrossProfitInteger },
    });

    let result = response?.data?.result;

    setNewGrossProfitInteger(result?.gross_profit_integer);
    setSalesEstimate(result);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <h3>Margin Details</h3>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <Typography gutterBottom>Set Gross Profit Percent for All</Typography>
                <TextField
                  type="number"
                  value={newGrossProfitInteger}
                  fullWidth
                  sx={{
                    '& input': {
                      padding: '8px',
                    },
                  }}
                  onChange={(e) => setNewGrossProfitInteger(e.target.value)}
                  InputProps={{
                    inputProps: {
                      min: 0,
                      max: 100,
                    },
                  }}
                  aria-labelledby="unit-repair-cost-input"
                />
                <Grid item xs={4} md={12}>
                  <Typography variant="h6" component="div" style={{ float: 'left' }}>
                    Actual GP Percent:
                  </Typography>
                  <Typography style={{ textAlign: 'right', color: 'green', fontWeight: 'bold', fontSize: 22 }}>
                    {salesEstimate?.actual_gp_percent} %
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} md={3}>
                <Button
                  style={{ marginTop: 31 }}
                  variant="contained"
                  color="primary"
                  onClick={handleSaveGrossProfitInteger}
                >
                  Save GP %
                </Button>
              </Grid>
              <Grid item xs={4} md={2}>
                <Typography variant="h6" component="div" style={{ textAlign: 'right' }}>
                  Total Gross Profit:
                </Typography>
                <Typography style={{ textAlign: 'right' }}>{salesEstimate?.gross_profit_display}</Typography>
              </Grid>
              <Grid item xs={4} md={2}>
                <Typography variant="h6" component="div" style={{ textAlign: 'right' }}>
                  Total Hours:
                </Typography>
                <Typography style={{ textAlign: 'right' }}>{salesEstimate?.total_hours}</Typography>
              </Grid>
              <Grid item xs={4} md={2}>
                <Typography variant="h6" component="div" style={{ textAlign: 'right' }}>
                  GPPMD:
                </Typography>
                <Typography style={{ textAlign: 'right' }}>{salesEstimate?.gross_profit_per_man_day}</Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default MarginDetails;
