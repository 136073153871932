import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AssetForm from '../components/AssetForm';
import SalesEstimateStepper from '../../navigation/components/SalesEstimateStepper';
import SalesEstimateLoading from '../../navigation/components/SalesEstimateLoading';
import { EstimateCustomersContext } from '../contexts/EstimateCustomersContext';
import { EstimateDetailsContext } from '../../estimateDetails/contexts/EstimateDetailsContext';
import EstimateInfoHeader from '../components/EstimateInfoHeader';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    width: '100%',
    padding: 0,
  },
  box: {
    width: '100%',
  },
  content: {
    marginLeft: '0px',
    padding: '80px 30px',
  },
}));

const ReviewAssets: React.FC = () => {
  const classes = useStyles();
  const { estimateId } = useParams<{ estimateId: string }>();

  const { fetchPreexistingRooftopAssets, siteAssetsLoading } = React.useContext(EstimateCustomersContext);
  const { salesEstimate, fetchSalesEstimate } = React.useContext(EstimateDetailsContext);

  React.useEffect(() => {
    fetchPreexistingRooftopAssets(estimateId);
    fetchSalesEstimate(estimateId);
  }, []);

  return (
    <Box className={classes.box}>
      <SalesEstimateStepper activeStep={1} salesEstimateId={salesEstimate?.id} />
      <EstimateInfoHeader salesEstimate={salesEstimate} />
      <Box>
        <Grid container>
          <Grid item xs={12} md={10}>
            <AssetForm salesEstimateId={estimateId} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ReviewAssets;
