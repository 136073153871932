import React, { useState, useContext } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import DataTable from '../../dataTable';
import ExpandedCostBreakdown from './ExpandedCostBreakdown';
import { IDataTableColumn } from 'react-data-table-component';
import { SalesEstimate } from '../model';
import { EstimateDetailsContext } from '../contexts/EstimateDetailsContext';
import axios from '../../utils/axios.utils';

interface CostSummaryProps {}

const CostSummary: React.FC<CostSummaryProps> = (props) => {
  const { salesEstimate, setSalesEstimate } = useContext(EstimateDetailsContext);

  const [isUpdatingProfit, setUpdatingProfit] = useState(false);

  // Initialize state for the gross profit values
  const [grossProfitData, setGrossProfitData] = useState(
    salesEstimate?.cost_summary?.map((item) => ({
      ...item,
      gross_profit_integer: item.gross_profit_integer || 35, // Default to 35 if not provided
    })),
  );

  const handleGrossProfitChange = async (costSummaryInfo, addend) => {
    setUpdatingProfit(true);

    const response: any = await axios.put(`sales_estimates/${salesEstimate?.id}/adjust_gpi_for_category`, {
      category: costSummaryInfo?.category,
      addend: addend,
    });

    setSalesEstimate(response.data.result);
    setUpdatingProfit(false);
  };

  const costSummaryColumns: IDataTableColumn<any>[] = [
    {
      name: 'Category',
      selector: 'category',
      sortable: true,
      cell: (row) => <p style={{ textTransform: 'capitalize', fontWeight: 700 }}>{row.category}</p>,
    },
    {
      name: 'Cost',
      selector: 'cost',
      sortable: true,
    },
    {
      name: 'Tax',
      selector: 'tax_amount',
      sortable: true,
    },
    {
      name: 'GP %',
      selector: 'gross_profit_integer',
      sortable: true,
      cell: (row, index) => (
        <div style={{ display: 'flex', alignItems: 'center', marginRight: -30 }}>
          <Button
            size="small"
            variant="contained"
            style={{ minWidth: '30px', marginRight: '5px' }}
            onClick={() => (isUpdatingProfit ? null : handleGrossProfitChange(row, -1))}
          >
            -
          </Button>
          <span>{row.gross_profit_integer}%</span>
          <Button
            size="small"
            variant="contained"
            style={{ minWidth: '30px', marginLeft: '5px' }}
            onClick={() => (isUpdatingProfit ? null : handleGrossProfitChange(row, 1))}
          >
            +
          </Button>
        </div>
      ),
    },
    {
      name: 'Margin',
      selector: 'gross_profit',
      sortable: true,
    },
    {
      name: 'Sale Price',
      selector: 'item_sale_price',
      sortable: true,
    },
  ];

  return (
    <>
      <Grid container spacing={3} className="display-only-for-print">
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <h3>Cost Summary</h3>
              <DataTable
                title=""
                columns={costSummaryColumns}
                data={grossProfitData}
                striped
                dense
                highlightOnHover
                pointerOnHover
                expandableRows
                expandableRowsComponent={<ExpandedCostBreakdown row />}
                noHeader
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={3} className="no-print">
        <Grid item xs={12}>
          <Card>
            <CardContent className="expandable-pricing-table-alignment">
              <h3 style={{ marginBottom: 10 }}>Cost Summary</h3>
              <DataTable
                title=""
                columns={costSummaryColumns}
                data={salesEstimate?.cost_summary?.map((item) => ({
                  ...item,
                  gross_profit_integer: item.gross_profit_integer || 35,
                }))}
                striped
                dense
                highlightOnHover
                pointerOnHover
                expandableRows
                expandableRowsComponent={<ExpandedCostBreakdown row />}
                noHeader
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default CostSummary;
