import React from 'react';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import DataTable from '../../dataTable';
import ExpandedAssetCost from './ExpandedAssetCost';
import { reviewAssetColumns } from '../constants/salesEstimateReviewColumns';

import { SalesEstimate } from '../model';

interface PrintableEstimateDetailsProps {
  salesEstimate: SalesEstimate;
}

const trueToYes = (value: boolean) => (value ? 'Yes' : 'No');

const PrintableEstimateDetails: React.FC<PrintableEstimateDetailsProps> = (props) => {
  let { salesEstimate } = props;

  return (
    <>
      <div className="container">
        <div className="header">
          <h1>
            <strong>Estimate:</strong> {salesEstimate.name}
          </h1>
        </div>

        <div className="section">
          <div className="section-title">Estimate Summary</div>
          <div className="details">
            <div>
              <strong>Customer:</strong> {salesEstimate?.customer?.name}
            </div>
            <div>
              <strong>Contact:</strong> {salesEstimate?.contact_name}
            </div>
            <div>
              <strong>Phone:</strong> {salesEstimate?.contact_phone || 'None'}
            </div>
          </div>
          <div className="details">
            <div>
              <strong>Address:</strong> {salesEstimate?.address}
            </div>
            <div>
              <strong>Salesperson:</strong> {salesEstimate?.owner_name}
            </div>
            <div>
              <strong>Last Updated:</strong> {salesEstimate?.updated_at}
            </div>
          </div>
          <div className="details">
            <div>
              <strong>Total Assets:</strong> {salesEstimate?.sales_estimate_assets?.length}
            </div>
            <div>
              <strong>Total Hours:</strong> {salesEstimate?.total_hours}
            </div>
            <div>
              <strong>Sale Price:</strong> {salesEstimate?.sale_price}
            </div>
          </div>
        </div>
        {salesEstimate?.sales_estimate_assets?.map((sales_estimate_asset, index) => (
          <div className="section">
            <div className="section-title">
              Equipment Replacement #{index + 1} ({sales_estimate_asset?.asset_number})
            </div>
            <div className="details">
              <div>
                <strong>Previous Manufacturer:</strong> {sales_estimate_asset?.asset?.manufacturer}
              </div>
              <div>
                <strong>Previous Model Number:</strong> {sales_estimate_asset?.asset?.model_number}
              </div>
              <div>
                <strong>Previous Unit Size:</strong> {sales_estimate_asset?.asset?.size}
              </div>
            </div>
            <div className="details">
              <div>
                <strong>Replacement Manufacturer:</strong> {sales_estimate_asset?.equipment_cost_record?.manufacturer}
              </div>
              <div>
                <strong>Replacement Model Number:</strong> {sales_estimate_asset?.equipment_cost_record?.model_number}
              </div>
              <div>
                <strong>Replacement Serial Number:</strong> {sales_estimate_asset?.equipment_cost_record?.unit_size}
              </div>
            </div>
            <table className="table">
              <tr>
                <th>Accessory</th>
                <th>Units</th>
                <th>Total Cost</th>
              </tr>
              <>
                {sales_estimate_asset?.curb_adapter && (
                  <tr>
                    <td>Curb Adapter</td>
                    <td>1</td>
                    <td>${sales_estimate_asset?.curb_adapter?.cost}</td>
                  </tr>
                )}
              </>
              <>
                {sales_estimate_asset?.system_accessory_records.map((system_accessory) => (
                  <tr>
                    <td>{system_accessory.name}</td>
                    <td>1</td>
                    <td>{system_accessory?.unit_cost}</td>
                  </tr>
                ))}
              </>
            </table>
          </div>
        ))}
        <div className="section">
          <div className="section-title">Material Details</div>
          <table className="table">
            <tr>
              <th>Material</th>
              <th>Units</th>
              <th>Total Cost</th>
            </tr>
            <>
              {salesEstimate?.material_costs?.map((material_cost) => (
                <>
                  <tr>
                    <td>{material_cost.name}</td>
                    <td>{material_cost.job_units}</td>
                    <td>{material_cost.total_cost}</td>
                  </tr>
                </>
              ))}
            </>
          </table>
        </div>
        <div className="section">
          <div className="section-title">Labor Details</div>
          <table className="table">
            <tr>
              <th>Category</th>
              <th>Total Hours</th>
              <th>Rate</th>
              <th>Total Cost</th>
            </tr>
            <>
              {salesEstimate?.labor_costs?.map((labor_cost) => (
                <>
                  <tr>
                    <td>{labor_cost.name}</td>
                    <td>{labor_cost.job_units}</td>
                    <td>{labor_cost.rate}</td>
                    <td>{labor_cost.total_cost}</td>
                  </tr>
                </>
              ))}
            </>
          </table>
        </div>
        <div className="section">
          <div className="section-title">Contractor Details</div>
          <table className="table">
            <tr>
              <th>Contractor</th>
              <th>Units</th>
              <th>Total Cost</th>
            </tr>
            <>
              {salesEstimate?.subcontractor_costs?.map((subcontractor_cost) => (
                <>
                  <tr>
                    <td>{subcontractor_cost.name}</td>
                    <td>{subcontractor_cost.job_units}</td>
                    <td>{subcontractor_cost.total_cost}</td>
                  </tr>
                </>
              ))}
            </>
          </table>
        </div>
        <div className="section">
          <div className="section-title">Miscellaneous Details</div>
          <table className="table">
            <tr>
              <th>Cost</th>
              <th>Units</th>
              <th>Total Cost</th>
            </tr>
            <>
              {salesEstimate?.miscellaneous_costs?.map((miscellaneous_cost) => (
                <>
                  <tr>
                    <td>{miscellaneous_cost.name}</td>
                    <td>{miscellaneous_cost.job_units}</td>
                    <td>{miscellaneous_cost.total_cost}</td>
                  </tr>
                </>
              ))}
            </>
          </table>
        </div>
        <div className="section">
          <div className="section-title">Additional Details</div>
          <div className="details">
            <div>
              <strong>Folder Clean:</strong> {trueToYes(salesEstimate?.sales_estimate_key_details?.folder_clean)}
            </div>
            <div>
              <strong>Tax Exempt:</strong> {trueToYes(salesEstimate?.sales_estimate_key_details?.tax_exempt)}
            </div>
            <div>
              <strong>Night Work:</strong> {trueToYes(salesEstimate?.sales_estimate_key_details?.night_work)}
            </div>
          </div>
          <div className="details">
            <div>
              <strong>Weekend Work:</strong> {trueToYes(salesEstimate?.sales_estimate_key_details?.wknd_work)}
            </div>
            <div>
              <strong>Urgency:</strong> {salesEstimate?.sales_estimate_key_details?.urgency}
            </div>
            <div>
              <strong>Permit Required:</strong> {trueToYes(salesEstimate?.sales_estimate_key_details?.permit_required)}
            </div>
          </div>
          <div className="details">
            <div>
              <strong>Closeout Type:</strong> {salesEstimate?.sales_estimate_key_details?.closeout_type}
            </div>
            <div>
              <strong>Startup Type:</strong> {salesEstimate?.sales_estimate_key_details?.startup_type}
            </div>
            <div>
              <strong>Lead Tech:</strong> {salesEstimate?.sales_estimate_key_details?.lead_tech}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrintableEstimateDetails;
