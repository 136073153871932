import React, { useEffect, useState, useContext } from 'react';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import EditIcon from '@mui/icons-material/Edit';
import DataTable from '../../dataTable/DataTable/DataTable';
import { EstimateDetailsContext } from '../../estimateDetails/contexts/EstimateDetailsContext';

interface CostGroupAccordionProps {
  group: any;
  initialGroupCosts: any[];
  handleAddButtonClicked: (title: string, unitLabel: string, type: string) => void;
}

const CostGroupAccordion: React.FC<CostGroupAccordionProps> = (props) => {
  const { group, initialGroupCosts, handleAddButtonClicked } = props;

  const { setCostRecordToEdit, setCostRecordEditModalOpen } = useContext(EstimateDetailsContext);

  const [groupCosts, setGroupCosts] = useState(initialGroupCosts);

  const handleRowClick = (row: any) => {
    setCostRecordToEdit(row);
    setCostRecordEditModalOpen(true);
  };

  const columns: any[] = [
    { name: 'Asset', selector: 'asset_number', sortable: true },
    { name: 'Description', selector: 'name', sortable: true },
    { name: 'Units', selector: 'job_units', sortable: true, grow: 0.5 },
    { name: 'Total Cost', selector: 'total_cost', sortable: true, grow: 0.5 },
    {
      name: 'Actions',
      cell: (row: any) => (
        <Grid container justifyContent="space-between">
          <Grid item xs={6}>
            <Button variant="text" onClick={() => handleRowClick(row)}>
              <EditIcon />
            </Button>
          </Grid>
        </Grid>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  useEffect(() => {
    setGroupCosts(initialGroupCosts);
  }, [initialGroupCosts]);

  return (
    <Grid item xs={12} style={{ marginBottom: 10 }}>
      <Accordion style={{ marginBottom: 5 }}>
        <AccordionSummary>
          <Typography>
            {group.title} Costs ({groupCosts?.length})
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DataTable
            title="Installation Cost Records"
            columns={columns}
            data={groupCosts}
            onRowClicked={handleRowClick}
            striped
            dense
            highlightOnHover
            pointerOnHover
            noHeader
            pagination
          />
          <Button
            variant="contained"
            onClick={() => handleAddButtonClicked(group.title, group.unit_label, group.type)}
            type="button"
          >
            Add {group.title} Cost
          </Button>
        </AccordionDetails>
      </Accordion>
      <Grid item xs={12} style={{ marginBottom: 15, display: 'flex', justifyContent: 'flex-start' }}></Grid>
    </Grid>
  );
};

export default CostGroupAccordion;
