import React, { useState, useEffect, useContext } from 'react';
import { Grid } from '@mui/material';
import FilterSelectInput from '../../form/components/FilterSelectInput';
import axios from '../../utils/axios.utils';
import { EstimateDetailsContext } from '../../estimateDetails/contexts/EstimateDetailsContext';
import { AuthenticationContext } from '../../../homeModules/auth/contexts/AuthenticationContext';

interface RecentEstimatesFilterBarProps {
  filterValues: Record<string, string>;
  setFilterValues: React.Dispatch<any>;
}

const RecentEstimatesFilterBar: React.FC<RecentEstimatesFilterBarProps> = (props) => {
  const { filterValues, setFilterValues } = props;

  const [filterChoices, setFilterChoices] = useState<any>({});

  const { fetchSalesEstimates } = useContext(EstimateDetailsContext);
  const { user } = useContext(AuthenticationContext);

  const fetchChoices = async () => {
    await axios.get<string, any>('sales_estimates/new').then((response) => {
      setFilterChoices(response?.data);
    });
  };

  useEffect(() => {
    if (user?.id) {
      fetchSalesEstimates(user?.id);
    }
    fetchChoices();
  }, []);

  const handleUserSelected = (value: string) => {
    setFilterValues({ ...filterValues, user_id_eq: value });
    fetchSalesEstimates(value);
  };

  const handleFiltersKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleUserSelected(filterValues?.user_id_eq);
    }
  };

  return (
    <Grid container spacing={1} alignItems="flex-start" onKeyDown={handleFiltersKeyPress}>
      <Grid item xs={12} md={3}>
        <FilterSelectInput
          value={filterValues?.user_id_eq}
          choices={filterChoices.user_choices}
          valueChanged={(value) => handleUserSelected(value)}
          label="User"
        />
      </Grid>
    </Grid>
  );
};

export default RecentEstimatesFilterBar;
