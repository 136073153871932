import react, { useState, Dispatch, SetStateAction, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import SelectInput from '../../form/components/SelectInput';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import axios from '../../utils/axios.utils';

import { SalesEstimate } from '../../estimateDetails/model';

interface UtilityDetailsFormProps {
  salesEstimate: SalesEstimate;
  setSalesEstimate: Dispatch<SetStateAction<SalesEstimate>>;
  currentStep: number;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  index: number;
}

const UtilityDetailsForm: React.FC<UtilityDetailsFormProps> = (props) => {
  let { salesEstimate, setSalesEstimate, currentStep, setCurrentStep, index } = props;

  let [salesEstimateFormValues, setSalesEstimateFormValues] = useState<SalesEstimate>({});
  let [formErrors, setFormErrors] = useState<any>({});
  let [providerChoices, setProviderChoices] = useState<any>({});

  let zip = salesEstimate?.site?.zip;

  useEffect(() => {
    setSalesEstimateFormValues(salesEstimate);
  }, [salesEstimate]);

  const fetchUtilityChoices = async () => {
    try {
      await axios.get(`zip_codes/${zip.slice(0, 5)}`).then((response: any) => {
        setProviderChoices(response.data.result.utility_providers);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (zip?.length >= 5) {
      fetchUtilityChoices();
    }
  }, [salesEstimate?.site]);

  let handleSaveClicked = async () => {
    try {
      const response: any = await axios.post('/sales_estimates', {
        sales_estimate: salesEstimateFormValues,
      });
      setSalesEstimateFormValues(response.data.result);
      setSalesEstimate((prevSalesEstimate) => ({
        ...prevSalesEstimate,
        ...response.data.result,
      }));
      setCurrentStep((currentStep) => currentStep + 1);
    } catch (error: any) {
      setFormErrors(error.response.data.error);
    }
  };

  const handleSummaryClicked = () => {
    if (currentStep === index) {
      setCurrentStep(-1);
    } else {
      setCurrentStep(index);
    }
  };

  return (
    <Accordion expanded={currentStep === index}>
      <AccordionSummary expandIcon={<ArrowDropDownIcon />} onClick={handleSummaryClicked}>
        <Grid item xs={12}>
          <h2 style={{ fontSize: '18px' }}>
            {providerChoices?.length > 0 ? 'Utility Details' : 'No Providers found for this Zip'}
          </h2>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          {providerChoices?.length > 0 && (
            <Grid item xs={12}>
              <SelectInput
                label="Utility Provider"
                errorMessage={formErrors?.utility_provider_id?.[0]}
                value={salesEstimateFormValues.utility_provider_id}
                choices={providerChoices}
                valueChanged={(value: string) =>
                  setSalesEstimateFormValues({ ...salesEstimateFormValues, utility_provider_id: value })
                }
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={handleSaveClicked}>
              Save Utility Details
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default UtilityDetailsForm;
