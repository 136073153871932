import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DataTable from '../../../modules/dataTable/DataTable/DataTable';
import axios from '../../../modules/utils/axios.utils';

// import { UpcomingJobsContext } from '../contexts/UpcomingJobsContext';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    width: '100%',
    padding: 0,
  },
  box: {
    width: '100%',
  },
  content: {
    marginLeft: '0px',
    padding: '80px 30px',
  },
}));

const upcomingJobsColumns = [
  {
    name: 'Site/WO #',
    selector: (row: any) => row.order_no,
    sortable: true,
  },
  {
    name: 'CHLR #',
    selector: (row: any) => row.chlr_no,
    sortable: true,
  },
  {
    name: 'Model #',
    selector: (row: any) => row.model_no,
    sortable: true,
  },
  {
    name: 'Serial #',
    selector: (row: any) => row.serial_no,
    sortable: true,
  },
  {
    name: 'Tech',
    selector: (row: any) => row.emp_id,
    sortable: true,
  },
  {
    name: 'Date',
    selector: (row: any) => row.scheduled_date,
    sortable: true,
  },
  {
    name: 'REFG',
    selector: (row: any) => row.refg,
    sortable: true,
  },
];

const UpcomingJobs: React.FunctionComponent = () => {
  const classes = useStyles();
  const history = useHistory();
  const [fetched, setFetched] = useState(false);
  const [chillerJobs, setChillerJobs] = useState(false);
  // const { upcomingJobs, fetchUpcomingJobs } = useContext(UpcomingJobsContext);

  const fetchChillerJobs = async (user_id = '') => {
    try {
      const response = await axios.get<string, any>('/chiller_logs/chiller_jobs', {
        params: { 'ransack[user_id_eq]': user_id },
      });
      setChillerJobs(response?.data?.result);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!fetched) {
      fetchChillerJobs();
      setFetched(true);
    }
  }, []);

  return (
    <Box className={classes.box}>
      <Box>
        <Grid justifyContent="space-between" container spacing={3}>
          <Grid item>
            <Typography variant="h4" component="h4">
              Upcoming Jobs
            </Typography>
          </Grid>
        </Grid>
        <Card>
          <CardContent>
            <DataTable
              title=""
              columns={upcomingJobsColumns}
              data={chillerJobs}
              onRowClicked={(row: any) => history.push(`/chiller_logs/${row.id}/spreadsheet`)}
              striped
              dense
              highlightOnHover
              pointerOnHover
              pagination
              noHeader
            />
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default UpcomingJobs;
