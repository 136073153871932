/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Card, CardContent, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useReactToPrint } from 'react-to-print';
import SalesEstimateStepper from '../../navigation/components/SalesEstimateStepper';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import Button from '@mui/material/Button';
import InformationField from '../../form/components/InformationField';
import ContactAndPhone from '../components/ContactAndPhone';
import EstimateInfoHeader from '../../estimateCustomers/components/EstimateInfoHeader';
import CostSummary from '../components/CostSummary';
import ProposalDetailAccordion from '../components/ProposalDetailAccordion';
import EstimateSummary from '../components/EstimateSummary';
import AssetList from '../components/AssetList';
import MarginDetails from '../components/MarginDetails';
import SalesEstimateChangeHistory from '../components/SalesEstimateChangeHistory';
import RebateDetails from '../components/RebateDetails';
import JobFolder from '../components/JobFolder';
import { EstimateDetailsContext } from '../../estimateDetails/contexts/EstimateDetailsContext';
import KeyDetailsCard from '../components/KeyDetailsCard';
import PrintableEstimateDetails from '../components/PrintableEstimateDetails';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const DecodingData = {
  id: 1,
  name: 'NextLink Labs',
};

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    width: '100%',
    padding: 0,
  },
  box: {
    width: '100%',
  },
  content: {
    marginLeft: '0px',
    padding: '80px 30px',
  },
}));

// const formatNumber = (number) => (number != null ? parseFloat(number).toFixed(2) : '');

const EstimateDetail: React.FunctionComponent = () => {
  const classes = useStyles();

  const { estimateId } = useParams<{ estimateId: string }>();
  const contentRef = React.useRef<HTMLDivElement>(null);
  const { salesEstimate, setSalesEstimate, fetchSalesEstimate } = useContext(EstimateDetailsContext);
  const [decoding, setDecoding] = useState<any>({ results: [] });
  const reactToPrintFn = useReactToPrint({ contentRef });

  useEffect(() => {
    fetchSalesEstimate(estimateId);
    // eslint-disable-next-line
  }, [estimateId]);

  return (
    <Box className={classes.box}>
      <SalesEstimateStepper activeStep={6} salesEstimateId={estimateId} />
      <br />
      <div ref={contentRef}>
        <Grid justifyContent="space-between" container spacing={3} className="no-print">
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <EstimateInfoHeader
              salesEstimate={salesEstimate}
              downloadButton={
                <Button variant="contained" color="primary" onClick={reactToPrintFn as any}>
                  Print Estimate
                </Button>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} style={{ display: 'flex', alignItems: 'center' }}>
              <Grid item xs={12} md={7}>
                <EstimateSummary salesEstimate={salesEstimate} />
              </Grid>
              <Grid item xs={12} md={5} style={{ display: 'flex', alignItems: 'center' }}>
                <ContactAndPhone salesEstimate={salesEstimate} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className="no-print">
            <MarginDetails />
          </Grid>
          <Grid item xs={12}>
            <CostSummary />
          </Grid>
          <Grid item xs={12} className="no-print">
            <ProposalDetailAccordion
              proposalDetailId={salesEstimate?.proposal_detail_id}
              salesEstimate={salesEstimate}
            />
          </Grid>
          <Grid item xs={12}>
            <JobFolder documents={salesEstimate?.documents} />
          </Grid>
          <Grid item xs={12}>
            <KeyDetailsCard
              keyDetails={salesEstimate?.sales_estimate_key_details}
              salesEstimate={salesEstimate}
              setSalesEstimate={setSalesEstimate}
            />
          </Grid>
          <Grid item xs={12}>
            <AssetList salesEstimate={salesEstimate} />
          </Grid>
          <Grid item xs={12}>
            <RebateDetails salesEstimate={salesEstimate} />
          </Grid>
          {/* <Grid item xs={12}>
            <SalesEstimateChangeHistory />
          </Grid> */}
        </Grid>
        <div className="display-only-for-print">
          <PrintableEstimateDetails salesEstimate={salesEstimate} />
        </div>
      </div>
    </Box>
  );
};

export default EstimateDetail;
