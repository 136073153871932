import react, { useState, Dispatch, SetStateAction, useEffect, useContext } from 'react';

import Grid from '@mui/material/Grid';
import TextInput from '../../form/components/TextInput';
import SelectInput from '../../form/components/SelectInput';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import axios from '../../utils/axios.utils';

import { SalesEstimate } from '../../estimateDetails/model';

interface ContactDetailsFormProps {
  salesEstimate: SalesEstimate;
  setSalesEstimate: Dispatch<SetStateAction<SalesEstimate>>;
  currentStep: number;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  index: number;
}

const ContactDetailsForm: React.FC<ContactDetailsFormProps> = (props) => {
  let { salesEstimate, setSalesEstimate, currentStep, setCurrentStep, index } = props;

  let [contactFormOptions, setContactFormOptions] = useState<any>({});
  let [contactFormValues, setContactFormValues] = useState<any>({});
  let [formErrors, setFormErrors] = useState<any>({});
  let [primaryBoxChecked, setPrimaryBoxChecked] = useState(true);
  let [disableContinue, setDisableContinue] = useState(true);

  const fetchContact = async (contactId) => {
    const response: any = await axios.get(`/contacts/${contactId}`);
    setContactFormValues(response.data.result);
  };

  let fetchContactFormOptions = async () => {
    if (!salesEstimate?.customer?.id) {
      return;
    }

    const response: any = await axios.get('/contacts/new', { params: { customer_id: salesEstimate?.customer?.id } });
    let newFormOptions = response.data;
    setContactFormOptions(newFormOptions);

    if (newFormOptions?.contact_choices?.length > 0) {
      let firstContact = newFormOptions?.contact_choices?.[0];
      setContactFormValues({ ...contactFormValues, id: firstContact.value });
      fetchContact(firstContact.value);
    }
  };

  useEffect(() => {
    setContactFormValues({
      ...salesEstimate?.contact,
      customer_id: salesEstimate?.customer?.id,
    });
    fetchContactFormOptions();
  }, [salesEstimate]);

  useEffect(() => {
    if (contactFormValues?.first_name && contactFormValues?.last_name && contactFormValues?.contact_type_id) {
      setDisableContinue(false);
    } else {
      setDisableContinue(true);
    }
  }, [contactFormValues]);

  const handleContactChosen = async (value) => {
    console.log(value);
    if (value === 0) {
      // Clear form values if a "new contact" is selected
      const resetFormValues = Object.keys(contactFormValues).reduce((acc, key) => {
        acc[key] = '';
        return acc;
      }, {});
      setContactFormValues({
        ...resetFormValues,
        id: 0,
        customer_id: salesEstimate?.customer?.id,
      });
    } else {
      fetchContact(value);
    }
  };

  let handleSaveClicked = async () => {
    let primarySet = '';
    if (primaryBoxChecked) {
      primarySet = 'A';
    }

    try {
      const response: any = await axios.post('/contacts', {
        contact: { ...contactFormValues, primary: primarySet },
        sales_estimate_id: salesEstimate?.id,
      });
      let updatedContact = response.data.result;
      setContactFormValues(updatedContact);
      setSalesEstimate((prevSalesEstimate) => ({
        ...prevSalesEstimate,
        contact: updatedContact,
      }));
      setCurrentStep((currentStep) => currentStep + 1);
    } catch (error: any) {
      setFormErrors(error.response.data);
    }
  };

  const handleSummaryClicked = () => {
    if (currentStep === index) {
      setCurrentStep(-1);
    } else {
      setCurrentStep(index);
    }
  };

  return (
    <Accordion expanded={currentStep === index} disabled={!salesEstimate?.customer?.id}>
      <AccordionSummary expandIcon={<ArrowDropDownIcon />} onClick={handleSummaryClicked}>
        <Grid item xs={12}>
          <h2 style={{ fontSize: '18px' }}>Contact Details</h2>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SelectInput
              label="Choose Contact"
              choices={contactFormOptions?.contact_choices}
              value={contactFormValues?.id?.toString()}
              valueChanged={(value: string) => handleContactChosen(parseInt(value))}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked
                  checked={primaryBoxChecked}
                  onChange={(event) => setContactFormValues(setPrimaryBoxChecked(event.target.checked))}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              }
              label="Set as Primary Contact"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              label="Contact First Name"
              errorMessage={formErrors?.first_name?.[0]}
              value={contactFormValues?.first_name}
              shrinkLabel
              valueChanged={(value: string) => setContactFormValues({ ...contactFormValues, first_name: value })}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              label="Contact Last Name"
              errorMessage={formErrors?.last_name?.[0]}
              value={contactFormValues?.last_name}
              shrinkLabel
              valueChanged={(value: string) => setContactFormValues({ ...contactFormValues, last_name: value })}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectInput
              label="Contact Type"
              choices={contactFormOptions?.contact_type_choices}
              errorMessage={formErrors?.contact_type_id?.[0]}
              value={contactFormValues?.contact_type_id}
              valueChanged={(value: string) => setContactFormValues({ ...contactFormValues, contact_type_id: value })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              label="Contact Email"
              errorMessage={formErrors?.email?.[0]}
              value={contactFormValues?.email}
              shrinkLabel
              valueChanged={(value: string) => setContactFormValues({ ...contactFormValues, email: value })}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <TextInput
              label="Phone 1"
              errorMessage={formErrors?.phone1?.[0]}
              value={contactFormValues?.phone1}
              shrinkLabel
              valueChanged={(value: string) => setContactFormValues({ ...contactFormValues, phone1: value })}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SelectInput
              label="Phone 1 Type"
              errorMessage={formErrors?.phone1code?.[0]}
              value={contactFormValues?.phone1code}
              choices={contactFormOptions.phone_type_choices}
              valueChanged={(value: string) => setContactFormValues({ ...contactFormValues, phone1code: value })}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <TextInput
              label="Phone 2"
              errorMessage={formErrors?.phone2?.[0]}
              value={contactFormValues?.phone2}
              shrinkLabel
              valueChanged={(value: string) => setContactFormValues({ ...contactFormValues, phone2: value })}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SelectInput
              label="Phone 2 Type"
              errorMessage={formErrors?.phone2code?.[0]}
              value={contactFormValues?.phone2code}
              choices={contactFormOptions.phone_type_choices}
              valueChanged={(value: string) => setContactFormValues({ ...contactFormValues, phone2code: value })}
            />
          </Grid>

          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={handleSaveClicked} disabled={disableContinue}>
              Save Contact Details
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default ContactDetailsForm;
