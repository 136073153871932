import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { EstimateDetailsContext } from '../../estimateDetails/contexts/EstimateDetailsContext';
import NewInstallationCostModal from './NewInstallationCostModal';
import EditInstallationCostModal from './EditInstallationCostModal';
import CostGroupAccordion from './CostGroupAccordion';
import { EstimateCostsContext } from '../contexts/EstimateCostsContext';
import { CircularProgress } from '@mui/material';

const InstallationCosts = (props) => {
  let history = useHistory();
  let { salesEstimateId } = props;

  let [newModalOpen, setNewModalOpen] = useState(false);
  let [modalTitle, setModalTitle] = useState('Material');
  let [modalUnitLabel, setModalUnitLabel] = useState('Unit');
  let [descriptionOptions, setDescriptionOptions] = useState([]);

  let { installationCosts } = useContext(EstimateCostsContext);

  let handleAddButtonClicked = (title: string, unitLabel: string, type: string) => {
    setModalTitle(title);
    setModalUnitLabel(unitLabel);
    setDescriptionOptions(installationCosts.filter((option: any) => option.primary_category === type));
    setNewModalOpen(true);
  };

  const { salesEstimate } = useContext(EstimateDetailsContext);

  let costGroups = [
    {
      title: 'Material',
      unit_label: 'Unit',
      type: 'material',
    },
    {
      title: 'Labor',
      unit_label: 'Hour',
      type: 'labor',
    },
    {
      title: 'Subcontractor',
      unit_label: 'Unit',
      type: 'subcontractor',
    },
    {
      title: 'Miscellaneous',
      type: 'miscellaneous',
      unit_label: 'Unit',
    },
  ];

  return salesEstimate?.id ? (
    <Grid container>
      <NewInstallationCostModal
        title={modalTitle}
        unitLabel={modalUnitLabel}
        newModalOpen={newModalOpen}
        setNewModalOpen={setNewModalOpen}
        descriptionOptions={descriptionOptions}
      />
      <EditInstallationCostModal />
      {costGroups.map((group) => {
        let groupCosts = salesEstimate[`${group.type}_costs`];
        let groupDescriptionOptions = installationCosts.filter((option: any) => option.primary_category === group.type);

        return (
          <CostGroupAccordion
            key={group.title}
            group={group}
            initialGroupCosts={groupCosts}
            handleAddButtonClicked={handleAddButtonClicked}
          />
        );
      })}
      <Button
        variant="contained"
        color="primary"
        onClick={() => history.push(`/rooftop_automation/${salesEstimateId}/review`)}
      >
        Submit Installation
      </Button>
    </Grid>
  ) : (
    <CircularProgress />
  );
};

export default InstallationCosts;
