import react, { useState, Dispatch, SetStateAction, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Button from '@mui/material/Button';

import SalesEstimateDetailsForm from './SalesEstimateDetailsForm';

import axios from '../../utils/axios.utils';

import { SalesEstimate } from '../../estimateDetails/model';
import { EstimateDetailsContext } from '../../estimateDetails/contexts/EstimateDetailsContext';
import { EstimateCustomersContext } from '../contexts/EstimateCustomersContext';

interface SalesEstimateDetailsAccordionProps {
  currentStep: number;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  index: number;
}

const SalesEstimateDetailsAccordion: React.FC<SalesEstimateDetailsAccordionProps> = (props) => {
  let history = useHistory();
  let { currentStep, setCurrentStep, index } = props;

  let { salesEstimate, setSalesEstimate } = useContext(EstimateDetailsContext);
  let { customerFormOptions } = useContext(EstimateCustomersContext);

  let [salesEstimateFormValues, setSalesEstimateFormValues] = useState<SalesEstimate>({});
  let [formErrors, setFormErrors] = useState<any>('');
  let [selectedOpportunity, setSelectedOpportunity] = useState<any>(null);

  let updateSelectedOpportunity = () => {
    setSelectedOpportunity(
      customerFormOptions?.opportunity_choices?.find((option) => option.value === salesEstimateFormValues?.xoi_job_id),
    );
  };

  useEffect(() => {
    let gpiToUse = salesEstimate?.gross_profit_integer || '30';
    setSalesEstimateFormValues({ ...salesEstimate, gross_profit_integer: gpiToUse });
  }, [salesEstimate]);

  useEffect(() => {
    updateSelectedOpportunity();
  }, [customerFormOptions, salesEstimateFormValues]);

  let handleSaveClicked = async () => {
    try {
      const response: any = await axios.post('/sales_estimates', {
        sales_estimate: salesEstimateFormValues,
      });
      setSalesEstimate((prevSalesEstimate) => ({
        ...prevSalesEstimate,
        ...response.data.result,
      }));
      setCurrentStep((currentStep) => currentStep + 1);
    } catch (error: any) {
      setFormErrors(error.response.data.error);
    }
  };

  const handleSummaryClicked = () => {
    if (currentStep === index) {
      setCurrentStep(-1);
    } else {
      setCurrentStep(index);
    }
  };

  return (
    <Accordion expanded={currentStep === index}>
      <AccordionSummary expandIcon={<ArrowDropDownIcon />} onClick={handleSummaryClicked}>
        <Grid item xs={12}>
          <h2 style={{ fontSize: '18px' }}>Estimate Details</h2>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <SalesEstimateDetailsForm
          salesEstimateFormValues={salesEstimateFormValues}
          setSalesEstimateFormValues={setSalesEstimateFormValues}
        />
        <Button variant="contained" color="primary" onClick={handleSaveClicked}>
          Save Estimate Details
        </Button>
      </AccordionDetails>
    </Accordion>
  );
};

export default SalesEstimateDetailsAccordion;
