import React, { useState, Dispatch, SetStateAction, useEffect, useContext } from 'react';

// Test the Expanded Specifx Details using a realistic record from the live db

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import Popper from '@mui/material/Popper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import TextInput from '../../form/components/TextInput';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import Save from '@mui/icons-material/Save';

import styled from '@mui/material/styles/styled';

import ExpandedSpecifxDetails from './ExpandedSpecifxDetails';

import { EquipmentCostRecord } from '../../estimateDetails/model';
import { EstimateCostsContext } from '../contexts/EstimateCostsContext';

import axios from '../../utils/axios.utils';

interface ReplacementEquipmentCardProps {
  initialEquipmentCostRecord: EquipmentCostRecord;
  setModalDetails: Dispatch<SetStateAction<any>>;
  setAssetDetailsModalOpen: Dispatch<SetStateAction<boolean>>;
  handleUnsavedChanges: (id: string, hasChanges: boolean) => void;
}

const ReplacementEquipmentCard: React.FC<ReplacementEquipmentCardProps> = (props) => {
  let { initialEquipmentCostRecord, setModalDetails, setAssetDetailsModalOpen, handleUnsavedChanges } = props;

  let { equipmentCostOptions } = useContext(EstimateCostsContext);

  let [equipmentCostRecordFormValues, setEquipmentCostRecordFormValues] = useState<EquipmentCostRecord>();
  let [currentEquipmentCostRecord, setCurrentEquipmentCostRecord] = useState<EquipmentCostRecord>();
  let [thisRecordNeedsSaving, setThisRecordNeedsSaving] = useState<boolean>(false);

  useEffect(() => {
    let relevantValues = {
      equipment_cost_id: initialEquipmentCostRecord.equipment_cost_id,
      unit_cost: initialEquipmentCostRecord.unit_cost,
    };
    setEquipmentCostRecordFormValues(relevantValues);
    setCurrentEquipmentCostRecord(relevantValues);
  }, [initialEquipmentCostRecord]);

  useEffect(() => {
    if (equipmentCostRecordFormValues) {
      let hasChanges = JSON.stringify(equipmentCostRecordFormValues) !== JSON.stringify(currentEquipmentCostRecord);
      handleUnsavedChanges(initialEquipmentCostRecord.id, hasChanges);
      setThisRecordNeedsSaving(hasChanges);
    }
  }, [equipmentCostRecordFormValues]);

  const CustomPopper = styled(Popper)(({ theme }) => ({}));

  const handleOpen = (details) => {
    setModalDetails(details);
    setAssetDetailsModalOpen(true);
  };

  const updateEquipmentCostRecord = async () => {
    let response: any = await axios.put(`/equipment_cost_records/${initialEquipmentCostRecord.id}`, {
      equipment_cost_record: equipmentCostRecordFormValues,
    });
    let updatedRecord = response.data.result;
    setEquipmentCostRecordFormValues(updatedRecord);
    setCurrentEquipmentCostRecord(updatedRecord);
  };

  const handleEquipmentSelected = async (value) => {
    setEquipmentCostRecordFormValues({
      ...equipmentCostRecordFormValues,
      equipment_cost_id: value?.value,
      unit_cost: value?.default_cost,
    });
  };

  return (
    <Card style={{ padding: '1% 2%' }}>
      <Grid container spacing={2} style={{ width: '100%' }}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={5}>
              <Typography style={{ fontSize: '20px' }}>
                <span style={{ fontWeight: 'bold' }}>{initialEquipmentCostRecord.asset_number}</span>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>
                {initialEquipmentCostRecord.previous_manufacturer} {initialEquipmentCostRecord?.previous_model}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography>size: {initialEquipmentCostRecord.previous_size}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          Replace with:
        </Grid>
        <Grid item xs={12} md={9} style={{ paddingTop: 15, marginBottom: 15 }}>
          <Autocomplete
            disablePortal
            PopperComponent={CustomPopper}
            id="combo-box-demo"
            options={[{ label: 'Select Replacement', value: 'Select Replacement' }, ...equipmentCostOptions]}
            getOptionLabel={(option: any) => option.label}
            renderOption={(props, option: any) => (
              <Box component="li" {...props} display="flex" alignItems="center">
                {option.label}
                {option.details && (
                  <IconButton
                    edge="end"
                    aria-label="details"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleOpen(option.details);
                    }}
                  >
                    <InfoIcon />
                  </IconButton>
                )}
              </Box>
            )}
            sx={{ width: '95%', float: 'left' }}
            value={
              equipmentCostOptions.filter(
                (option) => option?.value === equipmentCostRecordFormValues?.equipment_cost_id,
              )[0] || {
                label: 'Select Replacement',
                value: 'Select Replacement',
              }
            }
            onChange={(event, newValue: any) => handleEquipmentSelected(newValue)}
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>
        <Grid item xs={2}>
          <TextInput
            label="Cost"
            currency
            value={equipmentCostRecordFormValues?.unit_cost}
            valueChanged={(value: string) =>
              setEquipmentCostRecordFormValues({ ...equipmentCostRecordFormValues, unit_cost: value })
            }
          />
        </Grid>
        <Grid item xs={1}>
          <Button
            variant="contained"
            color="primary"
            style={{
              height: '55px',
              border: thisRecordNeedsSaving ? '5px solid red' : '1px solid #3f51b5',
            }}
            onClick={() => updateEquipmentCostRecord()}
          >
            <Save />
          </Button>
        </Grid>

        <Grid item xs={12} style={{ paddingLeft: '5%', paddingRight: '5%' }}>
          <Accordion>
            <AccordionSummary style={{ backgroundColor: '#dcf5e6' }}>
              <Typography style={{ fontWeight: 'bold' }}>
                Specifx Details (Decode Status: {initialEquipmentCostRecord.decoding_status})
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ExpandedSpecifxDetails salesEstimateAsset={initialEquipmentCostRecord as any} />
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </Card>
  );
};

export default ReplacementEquipmentCard;
