import React from 'react';
import { Alert, Button, IconButton, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { SnackBarContext } from '../contexts/SnackBarContext';

const TudiSnackbar: React.FC = () => {
  const { snackBarOpen, setSnackBarOpen, snackBarMessage, setSnackBarMessage, snackbarAlertSeverity } =
    React.useContext(SnackBarContext);

  return snackBarOpen ? (
    <>
      <Snackbar
        open={snackBarOpen}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={3000}
        onClose={() => {
          setSnackBarOpen(false);
          setSnackBarMessage('');
        }}
      >
        <Alert severity={snackbarAlertSeverity} variant="filled" sx={{ width: '100%' }}>
          {snackBarMessage}
        </Alert>
      </Snackbar>
    </>
  ) : (
    <></>
  );
};

export default TudiSnackbar;
