import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';

import { makeStyles } from '@mui/styles';

import { EstimateDetailsContext } from '../../estimateDetails/contexts/EstimateDetailsContext';

import SalesEstimateDetailsForm from '../../estimateCustomers/components/SalesEstimateDetailsForm';
import SalesEstimateStepper from '../../navigation/components/SalesEstimateStepper';

import axios from '../../utils/axios.utils';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    width: '100%',
    padding: 0,
  },
  box: {
    width: '100%',
  },
  content: {
    marginLeft: '0px',
    padding: '80px 30px',
  },
}));

const filterDuplicates = (choices) => {
  const uniqueLabels = new Set();
  return choices.filter((choice) => {
    if (uniqueLabels.has(choice.label)) {
      return false;
    }
    uniqueLabels.add(choice.label);
    return true;
  });
};

const ExistingCustomerSiteSelection: React.FC = () => {
  let classes = useStyles();
  let history = useHistory();

  const { setSalesEstimate } = useContext(EstimateDetailsContext);

  const [siteChoices, setSiteChoices] = useState<any[]>([]);
  const [salesEstimateFormValues, setSalesEstimateFormValues] = useState<any>({});
  const [siteSearchInputValue, setSiteSearchInputValue] = useState('');
  const [siteSearchLoading, setSiteSearchLoading] = useState(false);
  const [formErrors, setFormErrors] = useState<any>('');

  useEffect(() => {
    const fetchSites = async (query) => {
      if (query.length >= 0 && !siteSearchLoading) {
        setSiteSearchLoading(true);
        try {
          const response = await axios.get<any, any>('/sites', {
            params: { query },
          });
          setSiteChoices(response.data.result || []);
        } catch (error) {
          console.error('Error fetching sites:', error);
          setSiteChoices([]);
        } finally {
          setSiteSearchLoading(false);
        }
      } else {
        setSiteChoices([]);
      }
    };

    const debounceFetch = setTimeout(() => {
      fetchSites(siteSearchInputValue);
    }, 300);

    return () => clearTimeout(debounceFetch);
  }, [siteSearchInputValue]);

  let handleSaveClicked = async () => {
    try {
      const response: any = await axios.post('/sales_estimates', {
        sales_estimate: salesEstimateFormValues,
      });
      setSalesEstimate(response.data.result);
      let newId = response.data.result.id;
      history.push(`/rooftop_automation/${newId}/customer`);
    } catch (error: any) {
      setFormErrors(error.response.data.error);
    }
  };

  return (
    <Box className={classes.box}>
      <SalesEstimateStepper activeStep={0} />
      <Box>
        <Grid container>
          <Grid item xs={12} md={10}>
            <h2 style={{ fontSize: '36px', marginBottom: 10 }}>New Estimate</h2>
          </Grid>
          <Grid item xs={12}>
            <Card style={{ padding: '2%' }}>
              <h2 style={{ fontSize: '18px', marginBottom: 20 }}>Select A Site</h2>
              <Autocomplete
                disablePortal
                id="combo-box-site-search"
                options={filterDuplicates(siteChoices)}
                getOptionLabel={(option) => option.label || ''}
                sx={{ width: '100%' }}
                value={siteChoices.find((option) => option.value === salesEstimateFormValues?.site_id)}
                onChange={(event, newValue) =>
                  setSalesEstimateFormValues({ ...salesEstimateFormValues, site_id: newValue?.value })
                }
                onInputChange={(event, newInputValue) => setSiteSearchInputValue(newInputValue)}
                noOptionsText={
                  siteSearchInputValue?.length >= 2
                    ? 'No matching sites found, please check your spelling and try again.'
                    : 'Enter the site name here...'
                }
                renderInput={(params) => <TextField {...params} label="Site" />}
              />
              <br />
              <SalesEstimateDetailsForm
                salesEstimateFormValues={salesEstimateFormValues}
                setSalesEstimateFormValues={setSalesEstimateFormValues}
              />
              <Grid item xs={12} style={{ marginBottom: 15 }}>
                <p style={{ color: 'red' }}>{formErrors}</p>
              </Grid>
              <Button variant="contained" color="primary" onClick={handleSaveClicked}>
                Create Estimate
              </Button>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ExistingCustomerSiteSelection;
