import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Stepper, Step, StepLabel, Button, Grid, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
// import { UpcomingJobsContext } from '../contexts/UpcomingJobsContext';

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '100vh',
    width: '100%',
    padding: '20px',
  },
  stepContent: {
    marginTop: '20px',
    marginBottom: '20px',
  },
}));

const steps = ['Water Cooled Unit', 'Evaporator', 'Condenser', 'Compressor', 'Motor', 'Purge', 'Additional'];

function getStepContent(step: number, values: any, handleChange: (e: any, field: string) => void) {
  if (step === 0) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <TextField
            label="Chilled Water Setpoint"
            type="number"
            fullWidth
            value={values.chilledWaterSetpoint}
            onChange={(e) => handleChange(e, 'chilledWaterSetpoint')}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label="Outside Air Temp"
            type="number"
            fullWidth
            value={values.outsideAirTemp}
            onChange={(e) => handleChange(e, 'outsideAirTemp')}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label="Evap Enter Water Temp"
            type="number"
            fullWidth
            value={values.evapEnterWaterTemp}
            onChange={(e) => handleChange(e, 'evapEnterWaterTemp')}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label="Evap Leaving Water Temp"
            type="number"
            fullWidth
            value={values.evapLeavingWaterTemp}
            onChange={(e) => handleChange(e, 'evapLeavingWaterTemp')}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label="Evap GPM"
            type="number"
            fullWidth
            value={values.evapGpm}
            onChange={(e) => handleChange(e, 'evapGpm')}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label="Evap Delta T"
            type="number"
            fullWidth
            value={values.evapDeltaT}
            onChange={(e) => handleChange(e, 'evapDeltaT')}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label="Evap Delta P"
            type="number"
            fullWidth
            value={values.evapDeltaP}
            onChange={(e) => handleChange(e, 'evapDeltaP')}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label="Cond Enter Water Temp"
            type="number"
            fullWidth
            value={values.condEnterWaterTemp}
            onChange={(e) => handleChange(e, 'condEnterWaterTemp')}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label="Cond Leaving Water Temp"
            type="number"
            fullWidth
            value={values.condLeavingWaterTemp}
            onChange={(e) => handleChange(e, 'condLeavingWaterTemp')}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label="Cond GPM"
            type="number"
            fullWidth
            value={values.condGpm}
            onChange={(e) => handleChange(e, 'condGpm')}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label="Cond Delta T"
            type="number"
            fullWidth
            value={values.condDeltaT}
            onChange={(e) => handleChange(e, 'condDeltaT')}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label="Cond Delta P"
            type="number"
            fullWidth
            value={values.condDeltaP}
            onChange={(e) => handleChange(e, 'condDeltaP')}
          />
        </Grid>
      </Grid>
    );
  }
  if (step === 1) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <TextField
            label="Suction Line PSI"
            type="number"
            fullWidth
            value={values.suctionLinePsi}
            onChange={(e) => handleChange(e, 'suctionLinePsi')}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label="Saturation Temp"
            type="number"
            fullWidth
            value={values.satTempEvap}
            onChange={(e) => handleChange(e, 'satTempEvap')}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label="Suction Line Temp"
            type="number"
            fullWidth
            value={values.suctionLineTemp}
            onChange={(e) => handleChange(e, 'suctionLineTemp')}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label="Suction Superheat"
            type="number"
            fullWidth
            value={values.suctionSuperheat}
            onChange={(e) => handleChange(e, 'suctionSuperheat')}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label="Expansion Valve Position"
            type="number"
            fullWidth
            value={values.expansionValvePosition}
            onChange={(e) => handleChange(e, 'expansionValvePosition')}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label="Approach"
            type="number"
            fullWidth
            value={values.approachEvap}
            onChange={(e) => handleChange(e, 'approachEvap')}
          />
        </Grid>
      </Grid>
    );
  }
  if (step === 2) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <TextField
            label="Liquid Line PSI"
            type="number"
            fullWidth
            value={values.liquidLinePsi}
            onChange={(e) => handleChange(e, 'liquidLinePsi')}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label="Saturation Temp"
            type="number"
            fullWidth
            value={values.satTempCond}
            onChange={(e) => handleChange(e, 'satTempCond')}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label="Liquid Temp"
            type="number"
            fullWidth
            value={values.liquidTemp}
            onChange={(e) => handleChange(e, 'liquidTemp')}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label="Subcooling"
            type="number"
            fullWidth
            value={values.subcooling}
            onChange={(e) => handleChange(e, 'subcooling')}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label="Approach"
            type="number"
            fullWidth
            value={values.approachCond}
            onChange={(e) => handleChange(e, 'approachCond')}
          />
        </Grid>
      </Grid>
    );
  }
  if (step === 3) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <TextField
            label="Discharge Temp"
            type="number"
            fullWidth
            value={values.dischargeTemp}
            onChange={(e) => handleChange(e, 'dischargeTemp')}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label="Discharge PSI"
            type="number"
            fullWidth
            value={values.dischargePsi}
            onChange={(e) => handleChange(e, 'dischargePsi')}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label="Discharge Superheat"
            type="number"
            fullWidth
            value={values.dischargeSuperheat}
            onChange={(e) => handleChange(e, 'dischargeSuperheat')}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label="Oil Discharge Pressure"
            type="number"
            fullWidth
            value={values.oilDischargePressure}
            onChange={(e) => handleChange(e, 'oilDischargePressure')}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label="Oil PSI Net"
            type="number"
            fullWidth
            value={values.oilPsiNet}
            onChange={(e) => handleChange(e, 'oilPsiNet')}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label="Oil Supply Temp"
            type="number"
            fullWidth
            value={values.oilSupplyTemp}
            onChange={(e) => handleChange(e, 'oilSupplyTemp')}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label="Oil Filter Pressure Drop"
            type="number"
            fullWidth
            value={values.oilFilterPressureDrop}
            onChange={(e) => handleChange(e, 'oilFilterPressureDrop')}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label="%RLA"
            type="number"
            fullWidth
            value={values.rlaPercent}
            onChange={(e) => handleChange(e, 'rlaPercent')}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label="Slide Valve Position %"
            type="number"
            fullWidth
            value={values.slideValvePosition}
            onChange={(e) => handleChange(e, 'slideValvePosition')}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label="*PRV % Open"
            type="number"
            fullWidth
            value={values.prvPercentOpen}
            onChange={(e) => handleChange(e, 'prvPercentOpen')}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label="Starts"
            type="number"
            fullWidth
            value={values.starts}
            onChange={(e) => handleChange(e, 'starts')}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label="Hours"
            type="number"
            fullWidth
            value={values.hours}
            onChange={(e) => handleChange(e, 'hours')}
          />
        </Grid>
      </Grid>
    );
  }
  if (step === 4) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <TextField
            label="Motor Temp (Highest)"
            type="number"
            fullWidth
            value={values.motorTempHighest}
            onChange={(e) => handleChange(e, 'motorTempHighest')}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label="Amps L1"
            type="number"
            fullWidth
            value={values.ampsL1}
            onChange={(e) => handleChange(e, 'ampsL1')}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label="Amps L2"
            type="number"
            fullWidth
            value={values.ampsL2}
            onChange={(e) => handleChange(e, 'ampsL2')}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label="Amps L3"
            type="number"
            fullWidth
            value={values.ampsL3}
            onChange={(e) => handleChange(e, 'ampsL3')}
          />
        </Grid>
      </Grid>
    );
  }
  if (step === 5) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <TextField
            label="*Purge Minutes"
            type="number"
            fullWidth
            value={values.purgeMinutes}
            onChange={(e) => handleChange(e, 'purgeMinutes')}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label="*Suction Temp"
            type="number"
            fullWidth
            value={values.purgeSuctionTemp}
            onChange={(e) => handleChange(e, 'purgeSuctionTemp')}
          />
        </Grid>
      </Grid>
    );
  }
  if (step === 6) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <TextField
            label="VSD Command"
            type="number"
            fullWidth
            value={values.vsdCommand}
            onChange={(e) => handleChange(e, 'vsdCommand')}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label="VSD%"
            type="number"
            fullWidth
            value={values.vsdPercent}
            onChange={(e) => handleChange(e, 'vsdPercent')}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label="Oil Pump VSD Speed"
            type="number"
            fullWidth
            value={values.oilPumpVsdSpeed}
            onChange={(e) => handleChange(e, 'oilPumpVsdSpeed')}
          />
        </Grid>
      </Grid>
    );
  }
  return <Box>Unknown Step</Box>;
}

const UpcomingJobDetail: React.FunctionComponent = () => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const [job, setJob] = useState<any>(null);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [formValues, setFormValues] = useState({});

  useEffect(() => {
    setJob({
      id: '3',
      siteWo: 'WO-003',
      tech: 'Joe Johnson',
      chlrNumber: 'CHLR-1003',
      date: '2025-02-10',
      modelNumber: 'Model-C',
      refg: 'R-404A',
      serialNumber: 'SN-456789',
    });
  }, []);

  if (!job) {
    return <Box>No job found</Box>;
  }

  const handleChange = (e: any, field: string) => {
    // setFormValues((prev) => ({ ...prev, [field]: e.target.value }));
  };

  const handleNext = () => {
    setActiveStep((prev) => prev + 1);
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  return (
    <Box className={classes.root}>
      <Typography variant="h5">{job.siteWo}</Typography>
      <Typography variant="body1">Tech: {job.tech}</Typography>
      <Typography variant="body1">CHLR #: {job.chlrNumber}</Typography>
      <Typography variant="body1">Date: {job.date}</Typography>
      <Typography variant="body1">Model #: {job.modelNumber}</Typography>
      <Typography variant="body1">REFG: {job.refg}</Typography>
      <Typography variant="body1">Serial #: {job.serialNumber}</Typography>
      <Box className={classes.stepContent}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              {activeStep === index && (
                <Box>
                  {getStepContent(index, formValues, handleChange)}
                  <Grid container spacing={2} style={{ marginTop: '10px' }}>
                    <Grid item>
                      <Button disabled={activeStep === 0} onClick={handleBack} variant="outlined">
                        Back
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button onClick={handleNext} variant="contained" disabled={activeStep === steps.length - 1}>
                        Next
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Step>
          ))}
        </Stepper>
      </Box>
    </Box>
  );
};

export default UpcomingJobDetail;
