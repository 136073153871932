import React, { FC, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import SalesEstimateDetailsAccordion from './SalesEstimateDetailsAccordion';
import CustomerDetailsForm from './CustomerDetailsForm';
import ContactDetailsForm from './ContactDetailsForm';
import SiteDetailsForm from './SiteDetailsForm';
import UtilityDetailsForm from './UtilityDetailsForm';

import { EstimateDetailsContext } from '../../estimateDetails/contexts/EstimateDetailsContext';

interface ExistingCustomerFormProps {}

const ExistingCustomerForm: FC<ExistingCustomerFormProps> = (props) => {
  const history = useHistory();

  const { salesEstimate, setSalesEstimate } = useContext(EstimateDetailsContext);

  const [currentStep, setCurrentStep] = useState<number>(1);

  return (
    <Grid container>
      <Grid container>
        <Grid item xs={12}>
          <h2 style={{ fontSize: '18px' }}>Site Name</h2>
        </Grid>
        <Grid item xs={12} md={12}>
          <h2 style={{ fontSize: '28px', marginBottom: 10 }}>{salesEstimate?.site?.name}</h2>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SalesEstimateDetailsAccordion currentStep={currentStep} setCurrentStep={setCurrentStep} index={0} />
          </Grid>
          <Grid item xs={12}>
            <CustomerDetailsForm currentStep={currentStep} setCurrentStep={setCurrentStep} index={1} />
          </Grid>
          <Grid item xs={12}>
            <ContactDetailsForm
              salesEstimate={salesEstimate}
              setSalesEstimate={setSalesEstimate}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              index={2}
            />
          </Grid>
          <Grid item xs={12}>
            <SiteDetailsForm
              salesEstimate={salesEstimate}
              setSalesEstimate={setSalesEstimate}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              index={3}
            />
          </Grid>
          <Grid item xs={12}>
            <UtilityDetailsForm
              salesEstimate={salesEstimate}
              setSalesEstimate={setSalesEstimate}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              index={4}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ marginTop: 15 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => history.push(`/rooftop_automation/${salesEstimate?.id}/system`)}
        >
          Continue
        </Button>
      </Grid>
    </Grid>
  );
};

export default ExistingCustomerForm;
