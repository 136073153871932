import React from 'react';
import { Grid, Button } from '@mui/material';
import InformationField from '../../../modules/form/components/InformationField';

const row = {
  id: 71,
  order_no: '151683',
  system_no: '16987',
  chlr_no: 'CHLR-02',
  model_no: '19XR424235CMH64',
  serial_no: '3704Q69760',
  emp_id: 'ASTACIO',
  refg: 'R134A',
  created_at: '01/23/2025  9:42pm',
  updated_at: '01/23/2025  9:42pm',
  scheduled_date: '01/24/2025 12:00am',
};

const ChillerLogHeader: React.FC = () => {
  return (
    <Grid container style={{ marginLeft: -30, marginBottom: 10 }}>
      <Grid item xs={12} md={12}>
        <Grid container justifyContent="space-between"></Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item>
          <InformationField label="Site/WO #" value={row?.order_no} />
        </Grid>
        <Grid item>
          <InformationField label="CHLR #" value={row?.chlr_no} />
        </Grid>
        <Grid item>
          <InformationField label="Model #" value={row?.model_no} />
        </Grid>
        <Grid item>
          <InformationField label="Serial #" value={row?.serial_no} />
        </Grid>
        <Grid item>
          <InformationField label="Tech" value={row?.emp_id} />
        </Grid>
        <Grid item>
          <InformationField label="Date" value={row?.scheduled_date} />
        </Grid>
        <Grid item>
          <InformationField label="REFG" value={row?.refg} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ChillerLogHeader;
